import type { Pagination } from '@/graphql/types/Pagination';

export type AdaptedPagination = ReturnType<typeof paginationAdapter>;

export const paginationAdapter = (pagination: Pagination | null) => ({
  page: pagination?.page ?? 1,
  perPage: pagination?.per_page ?? 10,
  totalItems: pagination?.total_items ?? 0,
  totalPages: pagination?.total_pages ?? 1,
});

import { createPortal } from 'react-dom';
import styled from 'styled-components';

const StyledLoader = styled.div<{ color: string; absolute: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  position: ${({ absolute }) => (absolute ? 'absolute' : 'relative')};
  ${({ absolute }) =>
    absolute ? 'top: 0; bottom: 0; background: rgb(30 41 58 / 87%); z-index: 999;' : ''};
  align-items: center;
  justify-content: center;

  /* Loader 3 */
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 3px;
    border: 3px solid ${({ color }) => color};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) => color} transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type LoaderProps = { color?: string; absolute?: boolean; location?: HTMLElement };

const Loader = ({ color = '#ffffff', absolute = false }: LoaderProps) => {
  return (
    <StyledLoader color={color} absolute={absolute}>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </StyledLoader>
  );
};

export default function ({ color, absolute, location }: LoaderProps) {
  if (absolute) return createPortal(<Loader color={color} absolute />, location ?? document.body);

  return <Loader color={color} />;
}

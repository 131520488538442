import type { DocumentNode, TypedQueryDocumentNode } from 'graphql';
import { Key } from '@/hooks/useSWR/types';

export const getQueryKey = <R>(key: Key<R> | null) => {
  if (key === null) return null;
  const isKeyList = Array.isArray(key);
  const [query, _variables] = isKeyList ? key : [key, undefined];
  const operation = getOperationNames(query).sort().join(', ');

  if (isKeyList) return [operation, _variables];

  return operation;
};

export const getQueryString = (query: DocumentNode) => query.loc?.source.body;

export const getOperationNames = (query: DocumentNode) => {
  const _query = query as TypedQueryDocumentNode;
  const operationNames = _query.definitions
    .filter((definition) => definition.kind === 'OperationDefinition')
    .map((definition) => definition.name?.value);
  return operationNames;
};

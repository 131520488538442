import type { InputChangeEventHandler } from '@/types/form';
import { useNavigate } from 'react-router-dom';
import { Select } from '@/components/inputs';
import { userProperties } from '@/hooks/userProperties';
import { useActiveProperty } from '@/hooks/useActiveProperty';
import { Container } from './styled';
import { useAppDispatch } from '@/store/hooks';
import { propertyActions } from '@/store/slices';
import { Shimmer } from '../Shimmer/Shimmer';

export const PropertiesDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { properties, isLoading: isLoadingProperties } = userProperties();
  const { activePropertyId, isLoading: isLoadingActiveProperty } = useActiveProperty();

  const handlePropertyChange: InputChangeEventHandler = ({ value }) => {
    const foundProperty = properties?.find((p) => p.propertyId === value);
    if (foundProperty) {
      dispatch(propertyActions.setActivePropertyId(value));
      navigate(`/${value}/transactions`);
    }
  };

  if (isLoadingProperties || isLoadingActiveProperty)
    return (
      <Container>
        <Shimmer transparent height="38px" />
      </Container>
    );

  const hasNoProperties = !properties || properties?.length === 0;
  if (hasNoProperties)
    return (
      <Container>
        <span className="text-xs text-center">No property</span>
      </Container>
    );

  const propertiesOptions = properties?.map(({ name, propertyId }) => ({
    value: propertyId,
    label: name,
  }));

  return (
    <Container>
      <Select
        name="property"
        placeholder="- Select Property"
        options={propertiesOptions}
        className="color-white"
        width="100%"
        size="small"
        onChange={handlePropertyChange}
        styleContainer={{ padding: 0 }}
        defaultValue={activePropertyId}
      />
    </Container>
  );
};

import { Tooltip } from '../../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export const ErrorInfo = ({ errors }: any) => {
  const hasError = errors?.length > 0;
  if (!hasError) return null;

  return (
    <Tooltip
      trigger={<FontAwesomeIcon icon={faInfoCircle} className="text-red-500 text-xs" />}
      content={<span className="text-red-600">{errors[0]}</span>}
    />
  );
};

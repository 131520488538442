import type { updatePasswordVariables } from '@/graphql/types/updatePassword';
import { $UPDATE_PASSWORD } from '@/graphql/mutations';
import { useSWRMutate } from '../useSWR';

export const useUpdatePassword = () => {
  const { data, error, trigger, isMutating } = useSWRMutate<string, updatePasswordVariables>(
    $UPDATE_PASSWORD
  );

  return {
    error,
    message: data,
    isError: Boolean(error),
    isMutating,
    updatePassword: trigger,
  };
};

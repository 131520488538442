import { lazy } from 'react';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import GlobalStyle from './GlobalStyle';
import { AuthRoute, PrivateRoute, PropertyRoute } from './router/Router';
import { Navigate, Route } from 'react-router-dom';
import Signin from './pages/auth/components/Signin';
import ResetPassword from './pages/auth/components/ResetPassword';
import ForgotPassword from './pages/auth/components/ForgotPassword';
import Dashboard from './pages/dashboard/Dashboard';
import { store } from './store';
import { sentryInit, SentryRoutes } from './sentryInit';
import Layout from './layout/Layout';
import AuthLayout from './pages/auth/AuthLayout';
import { Suspense } from './layout/Suspense';
import './App.css';
import { PrismicProvider } from '@prismicio/react';
import { client } from './prismic';

const Overview = lazy(() => import('./pages/property/Overview'));
const LogsRoute = lazy(() => import('./pages/property/Logs'));
const MetersRoute = lazy(() => import('./pages/property/Meters'));
const TenantsRoute = lazy(() => import('./pages/property/Tenants'));
const TariffsRoute = lazy(() => import('./pages/property/Tariffs'));
const TransactionsRoute = lazy(() => import('./pages/property/Transactions'));
const Setting = lazy(() => import('./pages/property/Setting'));

sentryInit();
window.name = 'vend-app';

// TODO:: title is not included in the route object
const App = () => (
  <PrismicProvider client={client}>
    <Provider store={store}>
      <GlobalStyle />
      <Toaster position="top-center" />
      <SentryRoutes>
        <Route path="/auth" element={<AuthRoute />}>
          <Route index element={<Navigate to="signin" />} />
          <Route element={<AuthLayout />}>
            <Route path="signin" element={<Signin />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
          </Route>
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route element={<Layout showSideMenu />}>
            <Route index element={<Dashboard />} />
            <Route path=":propertyId" element={<PropertyRoute />}>
              <Route index element={<Navigate to="transactions" />} />
              <Route path="overview" element={<Suspense element={<Overview />} />} />
              <Route path="meters/tariffs/*" element={<Suspense element={<TariffsRoute />} />} />
              <Route path="meters/*" element={<Suspense element={<MetersRoute />} />} />
              <Route path="tenants/*" element={<Suspense element={<TenantsRoute />} />} />
              <Route path="logs/*" element={<Suspense element={<LogsRoute />} />} />
              <Route path="transactions/*" element={<Suspense element={<TransactionsRoute />} />} />
              <Route path="settings" element={<Suspense element={<Setting />} />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </SentryRoutes>
    </Provider>
  </PrismicProvider>
);

export default App;

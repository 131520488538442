import styled from 'styled-components';

export const Input = styled.input`
  border: 1px solid #2758a2;
  margin: 0 0.25rem;
  border-radius: 4px;
  text-align: center;
  font-weight: normal;
`;

export const Button = styled.button<{ selected?: boolean }>`
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-top: 1px solid #2758a2;
  border-bottom: 1px solid #2758a2;
  color: #2758a2;
  font-size: 12px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
  &, &:hover {
    background: #2758a2 !important;
    color: #ffffff !important;
  }
  `}

  &:disabled {
    cursor: not-allowed;
    background: #ececed;
    color: #5ea2c6;
  }

  &:not(:disabled):hover {
    background: #dce8f4;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  ${Button} {
    &:last-child {
      border-right: 1px solid #2758a2;
      border-radius: 0 4px 4px 0;
    }

    &:first-child {
      border-left: 1px solid #2758a2;
      border-radius: 4px 0 0 4px;
    }

    &:first-child:last-child {
      border-radius: 4px;
    }

    &:disabled {
      opacity: 0.8;
    }
  }
`;

import type { FetcherOptions, Key } from '@/hooks/useSWR/types';
import { STORAGE } from '@/utilities/constants';
import { fetcher, errorNotificationHandler } from '@/utilities/fetcher';
import { getOperationNames, getQueryString } from '@/utilities/getQueryKey';

export const enhancedFetcher =
  <V>(
    key: Key<V> | null | undefined,
    customFetcher?: (args: FetcherOptions) => Promise<any>,
    upload?: 'single' | 'multiple'
  ) =>
  (_: string, options?: { arg: Partial<V> }) => {
    if (key === undefined || key === null) return undefined;
    const isKeyList = Array.isArray(key);
    let [query, variables] = isKeyList ? key : [key, undefined];
    const queryString = getQueryString(query);
    const operationName = getOperationNames(query).join(', ');

    const token = localStorage.getItem(STORAGE.TOKEN);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
      ...(operationName === 'forgotPassword' && {
        'X-Referer-Origin': window.location.origin,
        'X-Link-Path': '/#/auth/reset-password',
      }),
    };
    const body = {
      variables: {
        ...variables,
        ...options?.arg,
      },
      operationName,
      query: queryString,
    };

    return (customFetcher ?? fetcher)({ body, headers, upload }).catch(errorNotificationHandler);
  };

import { truncateText } from '@/utilities/helper';

type TruncatedTextProps = {
  text: string;
  maxLength: number;
};
const TruncatedText = ({ text, maxLength }: TruncatedTextProps) => {
  return <span title={text}>{truncateText(text, maxLength)}</span>;
};

export default TruncatedText;

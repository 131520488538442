import { createColumnHelper } from '@tanstack/react-table';
import { AdaptedProperty } from '@/adapters/propertyAdapter';
import { formatDate, toNaira, toPercentage } from '@/utilities/helper';
import { PropertyColumnId } from './types';
import { Grid } from '@/components/Grid';
import { Badge } from '@/components/Badge';
import { getRoleBadge } from '@/utilities/getRoleBadge';
import { PropertyNameColumn } from '@/pages/dashboard/components/PropertyNameColumn';
import { Tags } from '@/pages/dashboard/components/Tags';

const propertyColumnHelper = createColumnHelper<AdaptedProperty>();

const propertyColumns = [
  propertyColumnHelper.accessor(PropertyColumnId.Role, {
    header: 'Role',
    cell: (info) => {
      const role = info.getValue();
      if (!role) return '-';
      return <Badge {...getRoleBadge(role)} />;
    },
  }),
  propertyColumnHelper.accessor(PropertyColumnId.Name, {
    header: 'Name',
    cell: (info) => {
      const property = info.row.original;
      return <PropertyNameColumn property={property} />;
    },
  }),
  propertyColumnHelper.accessor(PropertyColumnId.Email, {
    header: 'Contact',
    cell: (info) => {
      const email = info.getValue();
      const phoneNumber = info.row.original.phoneNumber;

      return (
        <Grid>
          <span>{email}</span>
          <span>{phoneNumber}</span>
        </Grid>
      );
    },
  }),
  propertyColumnHelper.accessor(PropertyColumnId.SubaccountId, {
    header: 'Subaccount ID',
  }),
  propertyColumnHelper.accessor(PropertyColumnId.RemmitalPercentage, {
    header: 'Remmital Percentage',
    cell: (info) => {
      const remmitalPercentage = info.getValue();
      return remmitalPercentage ? toPercentage(remmitalPercentage / 100, 1) : '-';
    },
  }),
  propertyColumnHelper.accessor(PropertyColumnId.SystemFlatCharge, {
    header: 'System Flat Charge',
    cell: (info) => toNaira(info.getValue()!),
  }),
  propertyColumnHelper.accessor(PropertyColumnId.EstateMaxFlatCharge, {
    header: 'Estate Max. Charge',
    cell: (info) => toNaira(info.getValue()!),
  }),
  propertyColumnHelper.accessor(() => PropertyColumnId.Tags, {
    header: 'Tags',
    cell: (info) => <Tags property={info.row.original} />,
  }),
  propertyColumnHelper.accessor(PropertyColumnId.CreatedAt, {
    header: 'Added On',
    cell: (info) => {
      let createdAt: string | number | null = info.getValue();
      if (!createdAt) return '-';
      createdAt = Number.isInteger(Number(createdAt)) ? Number(createdAt) : createdAt;
      return <span className="whitespace-nowrap">{formatDate(createdAt, 'MMM d yyyy')}</span>;
    },
  }),
] as const;
export const createPropertiesColumns = (exclude?: PropertyColumnId[]) =>
  propertyColumns.filter(
    (column: any) => !(exclude?.includes(column.id) || exclude?.includes(column.accessorKey))
  );

import type { resetPasswordVariables } from '@/graphql/types/resetPassword';
import { $RESET_PASSWORD } from '@/graphql/mutations';
import { useSWRMutate } from '../useSWR';

export const useResetPassword = () => {
  const { data, error, trigger, isMutating } = useSWRMutate<string, resetPasswordVariables>(
    $RESET_PASSWORD
  );

  return {
    error,
    message: data,
    isError: Boolean(error),
    isMutating,
    resetPassword: trigger,
  };
};

import { useProperty } from '@/hooks/useProperty';
import { useActivePropertyId } from '@/store/hooks/useActivePropertyId';

export const useActiveProperty = () => {
  const { activePropertyId } = useActivePropertyId();
  const { property: activeProperty, isLoading, mutate, error } = useProperty(activePropertyId);

  return {
    activeProperty,
    activePropertyId,
    isLoading,
    error,
    hasError: Boolean(error),
    refreshActiveProperty: mutate,
  };
};

import { useMemo, useState } from 'react';
import { Button, ButtonGroup } from './styled';
import { SizePerPageProps } from './types';

const SIZES = [10, 20, 50];

export const SizePerPage = ({
  totalSize,
  currentSize,
  sizes = SIZES,
  handleSizeChange,
}: SizePerPageProps) => {
  const _sizes = useMemo(() => sizes.filter((size) => totalSize > size), [sizes]);
  const _currentSize = useMemo(() => _sizes.find((size) => size >= currentSize) ?? totalSize, []);
  const [size, setSize] = useState(_currentSize);

  const onSizeChange = (s: number) => {
    setSize(s);
    handleSizeChange?.(s);
  };

  return (
    <ButtonGroup>
      {_sizes.map((s) => (
        <Button key={s} onClick={() => onSizeChange(s)} selected={s === size}>
          {s}
        </Button>
      ))}
      <Button
        onClick={() => onSizeChange(totalSize)}
        selected={totalSize === size || _sizes.length === 0}
        disabled={_sizes.length === 0}
      >
        All
      </Button>
    </ButtonGroup>
  );
};

import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '@/components/Logo';
import MojecLogo from '@/assets/images/mojec-logo.png';
import Icon from '@/components/Icon';
import { PrismicRichText, useSinglePrismicDocument } from '@prismicio/react';

const StyledAuth = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 100%;
  height: 100vh;
  overflow: hidden;

  h4 {
    font-size: 20px;
    font-weight: 600;
  }

  h6 {
    color: #5a6573;
    margin-bottom: 32px;
    max-width: 300px;
  }

  > div {
    min-height: 300px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    margin: auto auto;
    background: #ffffff;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.12);
  }
  .left-detail {
    width: 220px;
    height: auto;
    min-height: 100%;
    position: relative;
    background-image: url('/bg.jpg');
    // background-image: url('https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
    background-size: cover;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > span {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 12px;
    }
  }
  .right-detail {
    width: 320px;
    padding: 30px 30px 10px 30px;

    a {
      font-size: 12px;
    }
  }
`;

const AuthLayout = () => {
  const [document] = useSinglePrismicDocument('notification_banner');

  return (
    <>
      {document && (
        <div className="bg-[#2f68bf] text-white w-full p-4 text-md">
          <PrismicRichText field={document.data.notification_text} />
        </div>
      )}
      <StyledAuth>
        <div>
          <div className="left-detail">
            <span>
              <Logo src={MojecLogo} />
            </span>
            <span>
              <Icon name="etrack-white" width={20} /> &nbsp;&nbsp;Powered by Etrack
            </span>
          </div>
          <div className="right-detail">
            <Outlet />
            <div style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
              <a className="link">Contact Support</a>
            </div>
          </div>
        </div>
      </StyledAuth>
    </>
  );
};

export default AuthLayout;

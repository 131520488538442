import type {
  createPropertyVariables,
  createProperty_createProperty,
} from '@/graphql/types/createProperty';
import { $CREATE_PROPERTY } from '@/graphql/mutations';
import { useSWRMutate } from '../useSWR';
import { propertyAdapter } from '@/adapters';

export const useCreateProperty = () => {
  const { data, error, trigger, isMutating } = useSWRMutate<
    createProperty_createProperty,
    createPropertyVariables
  >($CREATE_PROPERTY);

  const property = data ? propertyAdapter(data) : undefined;

  return {
    error,
    property,
    isError: Boolean(error),
    isMutating,
    createProperty: trigger,
  };
};

import { $GET_USER } from '@/graphql/queries';
import { User } from '@/graphql/types/user';
import { useSWR } from '../useSWR';
import { userAdapter } from '@/adapters';

export const useCurrentUser = () => {
  const { data, error, isLoading } = useSWR<User>($GET_USER);

  const user = data ? userAdapter(data) : undefined; // adapt data here

  return {
    user,
    error,
    isLoading,
    hasError: Boolean(error),
  };
};

import type { Role } from '@/graphql/types/Role';
import { RoleSlug } from '@/utilities/getRoleBadge';

export type AdaptedRole = ReturnType<typeof roleAdapter>;

export const roleAdapter = (
  role: Omit<Role, 'role_id' | 'updated_at' | '__typename'> &
    Partial<Pick<Role, 'role_id' | 'updated_at'>>
) => ({
  slug: role.slug as RoleSlug,
  title: role.title,
  roleId: role.role_id,
  createdAt: role.created_at,
  updatedAt: role.updated_at,
});

import { AdaptedRole } from '@/adapters/roleAdapter';

export enum RoleSlug {
  MojecAgent = 'mojec_agent',
  MojecAdmin = 'mojec_admin',
  SuperAdmin = 'super_admin',
  PropertyAdmin = 'property_admin',
  PropertyOwner = 'property_owner',
}

const ROLE_COLOR_MAP = {
  [RoleSlug.MojecAgent]: '#9458c4',
  [RoleSlug.MojecAdmin]: '#03c39b',
  [RoleSlug.SuperAdmin]: '#dd493a',
  [RoleSlug.PropertyAdmin]: '#2659a2',
  [RoleSlug.PropertyOwner]: '#4392f1',
};

export const getRoleBadge = (role: Pick<Partial<AdaptedRole>, 'title' | 'slug'>) => {
  return {
    label: role.title ?? '',
    color: ROLE_COLOR_MAP[role.slug as RoleSlug] ?? 'gray',
  };
};

import styled from 'styled-components';
import { GridProps } from './types';
import { Align, Sizes, Direction } from './constants';

export const Grid = styled.div<GridProps>`
  ${({ grid, columns = 1, rows = 1 }) =>
    grid
      ? `
    display: grid;
    grid-template-rows: ${Array(rows).fill('1fr').join(' ')};
    grid-template-columns: ${Array(columns).fill('1fr').join(' ')};
  `
      : `
    display: flex;
  `};

  ${({ spacing = 'sm', alignX, alignY, direction = 'vertical' }) => `
    gap: ${Sizes[spacing]}px;
    flex-direction: ${Direction[direction]};

    ${
      direction.includes('horizontal')
        ? `
    align-items: ${alignY ? Align[alignY] : 'normal'};
    justify-content: ${alignX ? Align[alignX] : 'normal'};
    `
        : `
    align-items: ${alignX ? Align[alignX] : 'normal'};
    justify-content: ${alignY ? Align[alignY] : 'normal'};
    `
    }
  `}

  ${({ flex }) =>
    flex &&
    `
    > * {
      flex: ${flex};
    }
    `}
`;

import React, { MetaHTMLAttributes } from 'react';
import { Helmet } from 'react-helmet';
import { PrismicRichText, useSinglePrismicDocument } from '@prismicio/react';
import Header from './Header';
import Footer from './Footer';
import { Sidebar } from './Sidebar';
import { Outlet } from 'react-router-dom';
import { Container } from './styled';

type LayoutProps = {
  showHeader?: boolean;
  showFooter?: boolean;
  showSideMenu?: boolean;
  metas?: MetaHTMLAttributes<unknown>[];
};

const Layout = ({
  metas,
  showHeader = false,
  showFooter = false,
  showSideMenu = false,
}: LayoutProps) => {
  const [document] = useSinglePrismicDocument('notification_banner');

  return (
    <>
      <Helmet>
        <title>Mojec VendPower Administrator Dashboard</title>
        {metas?.map((meta) => (
          <meta {...meta} />
        ))}
      </Helmet>
      <Container>
        {showSideMenu && <Sidebar />}
        <main aria-hidden>
          <div id="main">
            {document && (
              <div className="bg-[#2f68bf] text-white w-full p-4 text-md">
                <PrismicRichText field={document.data.notification_text} />
              </div>
            )}
            {showHeader && <Header />}
            <section className="scrollbar">
              <Outlet />
            </section>
            {showFooter && <Footer />}
          </div>
        </main>
      </Container>
    </>
  );
};

export default React.memo(Layout);

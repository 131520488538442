import { type Key } from 'swr';
import useSWRMutation, { type SWRMutationConfiguration } from 'swr/mutation';
import { Key as CustomKey } from '@/hooks/useSWR/types';
import { getQueryKey } from '@/utilities/getQueryKey';
import { enhancedFetcher } from './utilities/enhancedFetcher';

export const useSWRMutate = <T, V extends Record<string, any>>(
  query: CustomKey<V> | null,
  options?: SWRMutationConfiguration<T | undefined, any, Partial<V>>
) =>
  useSWRMutation<T | undefined, any, Key, Partial<V>>(
    getQueryKey(query),
    enhancedFetcher(query),
    options
  );

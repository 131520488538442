import { Grid } from '../Grid';
import { ContainerProps } from './types';

export const Container = ({ header, action, children }: ContainerProps) => (
  <div>
    {header && (
      <div className="flex justify-between gap-8 mb-6">
        <h2 className="mb-0">{header}</h2>
        {action}
      </div>
    )}
    <Grid spacing="lg">{children}</Grid>
  </div>
);

import { AdaptedPaymentTrail } from '@/adapters/paymentTrailAdapter';
import { Flatten } from '@/types/utils';

export enum PaymentStatus {
  START = 'START',
  PASS = 'PASS',
  FAIL = 'FAIL',
  NEXT = 'NEXT',
  DEFAULT = 'DEFAULT',
}

export enum PaymentTrailStep {
  GQL_PREPAYMENT = 'GQL_PREPAYMENT',
  GQL_SAVE_PAYMENT = 'GQL_SAVE_PAYMENT',
  GQL_SAVE_INTERMEDIATE_PAYMENT = 'GQL_SAVE_INTERMEDIATE_PAYMENT',
  GQL_DISCO_SAVE_PAYMENT = 'GQL_DISCO_SAVE_PAYMENT',
  GQL_RETRY_TOKEN = 'GQL_RETRY_TOKEN',
  GQL_DISCO_RETRY_PAYMENT = 'GQL_DISCO_RETRY_PAYMENT',
  GQL_REQUERY_PAYMENT = 'GQL_REQUERY_PAYMENT',
  GQL_REQUERY_INTERMEDIATE_PAYMENT = 'GQL_REQUERY_INTERMEDIATE_PAYMENT',
  REST_WEB_GATEWAY = 'REST_WEB_GATEWAY',
  REST_WEB_GATEWAY_WEBHOOK = 'REST_WEB_GATEWAY_WEBHOOK',
  FLW_TNX_BRKDWN = 'FLW_TNX_BRKDWN',
  FLW_VERIFY = 'FLW_VERIFY',
  FLW_SUBMIT_PAYMENT = 'FLW_SUBMIT_PAYMENT',
  TOKEN_GEN = 'TOKEN_GEN',
  WALLET_GET_TXN_BKDWN = 'WALLET_GET_TXN_BKDWN',
  WALLET_GET_TXN_BY_REF = 'WALLET_GET_TXN_BY_REF',
  WALLET_SAVE_TXN = 'WALLET_SAVE_TXN',
}

export type TPaymentTrailFlow = {
  main: PaymentTrailStep;
  subActions: (PaymentTrailStep | TPaymentTrailFlow)[];
};

export type TPaymentAction = Flatten<AdaptedPaymentTrail>;

export type TPaymentActionNested = (TPaymentAction | TPaymentActionNested)[];

export type PaymentTrailProps = {
  group: TPaymentActionNested;
};

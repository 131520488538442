import { Grid } from '@/components/Grid';
import { BreadCrumb } from './BreadCrumb';
import { Navigator } from './Navigator';
import { SizePerPage } from './SizePerPage';
import { PaginationProps } from './types';

export const Pagination = ({ page: pagination, handlePageChange }: PaginationProps) => {
  const { page: currentPage, perPage, totalPages, totalItems } = pagination;
  const to = Math.min(currentPage * perPage, totalItems);
  const from = Math.max(1, (currentPage - 1) * perPage);

  const handleChange = (data: { size?: number; page?: number }) => {
    const size = data.size ?? perPage;
    let page = data.page ?? currentPage;
    if (size * page > totalItems) {
      page = Math.ceil(totalItems / size);
    }

    handlePageChange?.({ size, page });
  };

  if (totalItems === 0) return null;

  return (
    <Grid direction="horizontal" alignX="between" alignY="center">
      <Grid direction="horizontal" alignY="center" spacing="lg">
        <SizePerPage
          currentSize={perPage}
          totalSize={totalItems}
          handleSizeChange={(size) => handleChange({ size })}
        />
        <BreadCrumb from={from} to={to} size={totalItems} />
      </Grid>
      <Navigator
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={(page) => handleChange({ page })}
      />
    </Grid>
  );
};

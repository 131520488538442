import type { PayloadAction } from '@reduxjs/toolkit';
import type { PropertyState } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { Slice } from '../types';
import { STORAGE } from '@/utilities/constants';
import { localGet, localRemove, localSet } from '@/utilities/forage';

const initialState = {
  activePropertyId: localGet(STORAGE.ACTIVE_PROPERTY),
} as PropertyState;

export const propertySlice = createSlice({
  name: Slice.Property,
  initialState,
  reducers: {
    setActivePropertyId: (state, action: PayloadAction<string>) => {
      const propertyId = action.payload;
      state.activePropertyId = propertyId;
      localSet({ key: STORAGE.ACTIVE_PROPERTY, data: propertyId });
    },
    clearActivePropertyId: (state) => {
      state.activePropertyId = '';
      localRemove(STORAGE.ACTIVE_PROPERTY);
    },
  },
});

// Action creators are generated for each case reducer function
export const propertyActions = propertySlice.actions;
export const propertyReducer = propertySlice.reducer;

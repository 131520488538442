import Toast from 'react-hot-toast';
import { onlyDefined } from './helper';
import { GRAPH_URL } from './constants';
import { localClear } from './forage';

type Body = Partial<{ operationName: string; variables: any }>;

type FetcherArgs = Partial<{
  body: Body;
  headers: HeadersInit;
  upload?: 'single' | 'multiple';
}>;

const singleUploadBody = (body: Body) => {
  const file: File = body.variables.file;
  body.variables.file = null;

  const formData = new FormData();
  formData.append('operations', JSON.stringify(body));
  const map = '{"0": ["variables.file"]}';
  formData.append('map', map);
  formData.append('0', file);
  return formData;
};

const multiUploadBody = (body: Body) => {
  const files: File[] = body.variables?.files;
  body.variables.files = files.map(() => null);

  const formData = new FormData();
  formData.append('operations', JSON.stringify(body));
  const map = files.map((_, i) => `"${i}": ["variables.files.${i}"]`).join(', ');
  formData.append('map', map);
  files.forEach((file, i) => formData.append(String(i), file));
  return formData;
};

const composeBodyFormData = (upload: 'single' | 'multiple', body: Body) =>
  (upload === 'single' ? singleUploadBody : multiUploadBody)(body);

export const fetcher = ({ body, headers, upload }: FetcherArgs = { body: {} }) => {
  let preparedBody,
    preparedHeaders = { ...headers };

  if (upload) {
    preparedBody = composeBodyFormData(upload, body!);
    delete (preparedHeaders as any)['Content-Type'];
  } else {
    preparedBody = JSON.stringify(onlyDefined(body));
  }

  return fetch(GRAPH_URL, {
    method: 'POST',
    body: preparedBody,
    headers: preparedHeaders,
  }).then(async (res) => {
    const { data, errors } = await res.json();
    const operationData = body?.operationName ? data?.[body.operationName] : {};
    if (res.ok && (operationData || !errors)) return operationData;
    throw errors;
  });
};

export const errorNotificationHandler = (
  errors: { message: string }[] | string | { message: string }
) => {
  let messages;
  if (Array.isArray(errors)) {
    messages = errors.map(({ message }) => message).join('\n\n');
  } else if (typeof errors === 'string') {
    messages = errors;
  } else {
    messages = errors?.message;
  }
  Toast.error(messages ?? 'Something went wrong! Could be your network!');
  if (messages?.startsWith('Invalid token')) {
    localClear();
    window.location.assign('/#/auth/signin');
    return null;
  }
  throw errors;
};

import type { HTMLFormEventHandler, InputChangeEventHandler } from '@/types/form';
import { useEffect, useState } from 'react';
import Toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, PasswordInput } from '@/components/inputs';
import Button from '@/components/Button';
import { useLogin } from '@/hooks/useLogin';
import { useAppDispatch } from '@/store/hooks';
import { propertyActions, userActions } from '@/store/slices';
import { Grid } from '@/components/Grid';

const Signin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { login, user, isMutating } = useLogin();
  const [formData, setFormData] = useState({ email: '', password: '' });

  useEffect(() => {
    if (user?.token) {
      dispatch(userActions.login(user));
      dispatch(propertyActions.setActivePropertyId(user.propertiesId?.[0]));
      Toast.success('Successfully signed in!');
      navigate(location.state?.from ?? '/');
    }
  }, [user]);

  const handleSubmit: HTMLFormEventHandler = (e) => {
    e.preventDefault();
    login(formData);
  };

  const handleInputChange: InputChangeEventHandler = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <>
      <Grid spacing="lg">
        <h4>Welcome Back</h4>
        <h6>Welcome back! Log in to your account to view what&apos;s happened today</h6>
      </Grid>
      <Grid as="form" spacing="sm" onSubmit={handleSubmit}>
        <Grid spacing="lg">
          <Input
            label="Email"
            name="email"
            onChange={handleInputChange}
            value={formData.email}
            validate="required|email"
          />
          <PasswordInput
            label="Password"
            name="password"
            onChange={handleInputChange}
            value={formData.password}
            validate="required"
          />
        </Grid>
        <Grid alignX="end" spacing="lg">
          <span className="text-sm">
            Forgot password? Click{' '}
            <Link to="/auth/forgot-password" state={location.state}>
              here
            </Link>
          </span>
          <Button value="Sign In" size="lg" type="submit" loading={isMutating} width={100} />
        </Grid>
      </Grid>
    </>
  );
};

export default Signin;

import { ChangeEvent, ChangeEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { ButtonGroup, Button, Input } from './styled';
import { NavigatorProps } from './types';

export const Navigator = ({
  currentPage: _currentPage,
  totalPages,
  handlePageChange,
}: NavigatorProps) => {
  const currentPage = _currentPage > 0 ? _currentPage : 1; // current page cannot be less than 1
  const [page, setPage] = useState(currentPage); // only for visual cues

  useEffect(() => setPage(currentPage), [currentPage]);

  const onPageChange = (p: number) => {
    if (!Number.isInteger(p)) return;
    if (currentPage === p) return;
    if (p > totalPages) handlePageChange?.(totalPages);
    if (p >= 1 && p <= totalPages) handlePageChange?.(p);
  };

  const firstPage = () => onPageChange?.(1);
  const lastPage = () => onPageChange?.(totalPages);
  const previousPage = () => {
    const page = currentPage - 1;
    if (page >= 1) onPageChange?.(page);
  };
  const nextPage = () => {
    const page = currentPage + 1;
    if (page <= totalPages) onPageChange?.(currentPage + 1);
  };
  const handleInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = Number(e.target.value || 0);
    onPageChange(value);
  };

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }

    if (e.key === 'Enter') {
      const value = Number((e.target as HTMLInputElement).value || 0);
      onPageChange(value);
    }
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const isSinglePage = totalPages === 1;

  const handleInputChange = (e: any) => {
    setPage(e.target.value);
  };

  return (
    <div className="flex">
      <ButtonGroup>
        <Button onClick={firstPage} disabled={isFirstPage}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button onClick={previousPage} disabled={isFirstPage}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
      </ButtonGroup>
      <Input
        type="text"
        value={page}
        className="w-10"
        onBlur={handleInput}
        onKeyUp={handleKeyUp}
        onChange={handleInputChange}
        disabled={isSinglePage}
      />
      <ButtonGroup>
        <Button onClick={nextPage} disabled={isLastPage}>
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
        <Button onClick={lastPage} disabled={isLastPage}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Button>
      </ButtonGroup>
    </div>
  );
};

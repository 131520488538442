import { isEmpty } from './helper';

/**
 * localGet
 *
 * get data from local storage based on 'key'
 * @param key
 * @returns {Promise<any>}
 */
const localGet = <T>(key: string) => {
  const value = localStorage.getItem(key);
  if (!value) return;

  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return value as T;
  }
};

/**
 * localSet
 *
 * set data in local storage based on 'key'
 * @param {{key: string; data: T}} obj
 * @returns {Promise<any>}
 */
const localUpdate = <T>(obj: { key: string; data: T }) => {
  if (obj && obj.key) {
    const currentData = localGet<T>(obj.key);
    let newData;

    if (Array.isArray(obj.data) && Array.isArray(currentData)) {
      newData = [...currentData, ...obj.data];
    } else if (!isEmpty(currentData) && !isEmpty(obj.data)) {
      newData = { ...currentData, ...obj.data };
    } else {
      newData = obj.data;
    }

    return localSet({ key: obj.key, data: newData });
  }
};

/**
 * localSet
 *
 * set data in local storage based on 'key'
 * @param {{key: string; data: T}} obj
 * @returns {void}}
 */
const localSet = <T>(obj: { key: string; data: T }) => {
  const { key, data } = obj;
  if (key && data !== undefined && data !== null) {
    if (typeof data === 'string') {
      localStorage.setItem(key, data);
      return;
    }
    localStorage.setItem(key, JSON.stringify(data));
  }
};

/**
 * localRemove
 *
 * remove data in local storage based on 'key'
 * @param {{key: string; data: T}} obj
 * @returns {void}}
 */
const localRemove = (key: string) => {
  if (key) localStorage.removeItem(key);
};

/**
 * localClear
 *
 * clear storage
 * @returns {void}
 */
const localClear = () => {
  return localStorage.clear();
};

export { localGet, localSet, localUpdate, localRemove, localClear };

import styled from 'styled-components';

export const Container = styled.div`
  background: transparent !important;
  height: 58px !important;
  display: flex;
  align-items: center;
  padding: 10px 0 !important;

  > * {
    flex: 1;
  }

  select {
    background: transparent;
    color: white;
    outline: none;
    padding: 0 2px;
  }
`;

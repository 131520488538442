import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Navigation } from 'react-minimal-side-navigation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SIDE_MENU } from '@/utilities/constants';
import MojecLogo from '@/assets/images/mojec-logo.png';
import CreateProperty from '@/pages/property/CreateProperty';
import UserProfile from '@/pages/user/UserProfile';
import Icon from '@/components/Icon';
import Logo from '@/components/Logo';
import Button from '@/components/Button';
import { PropertiesDropdown } from '@/components/PropertiesDropdown';
import { useActiveProperty } from '@/hooks/useActiveProperty';
import {
  CreatePropertyWrapper,
  MenuToggleWrapper,
  SideMenuBottom,
  StyledActionArea,
  StyledLogo,
  StyledSideMenu,
} from './styled';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { useAppDispatch } from '@/store/hooks';
import { userActions } from '@/store/slices';
import { CurrentUser } from './components/CurrentUser';
import { CurrentRole } from './components/CurrentRole';
import { useAppSettings } from '@/store/hooks/useAppSettings';
import { withActivePropertyId } from './utility';
import { RoleSlug } from '@/utilities/getRoleBadge';

export const Sidebar = React.memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false);
  const dispatch = useAppDispatch();
  const { isSideMenuExpanded, toggleSideMenuExpanded } = useAppSettings();
  const { activeProperty, activePropertyId } = useActiveProperty();
  const isPropertyOwner = activeProperty?.role?.slug === RoleSlug.PropertyOwner;
  const isSettingsAllowed = [
    RoleSlug.SuperAdmin,
    RoleSlug.PropertyAdmin,
    RoleSlug.PropertyOwner,
  ].includes(activeProperty?.role?.slug!);
  const sideMenuItems = useMemo(
    () => withActivePropertyId(SIDE_MENU, { activePropertyId, isSettingsAllowed }),
    [activePropertyId, isSettingsAllowed]
  );

  const matchedNavigationPath = () => {
    const matchedItem = sideMenuItems?.find((item) =>
      location.pathname.startsWith(item.itemId)
    )?.itemId;
    return matchedItem ?? '/';
  };

  const logout = () => {
    dispatch(userActions.logout());
  };

  return (
    <>
      <StyledSideMenu
        aria-expanded={isSideMenuExpanded}
        className={clsx({
          'is-expanded': isSideMenuExpanded,
        })}
      >
        <MenuToggleWrapper aria-expanded={isSideMenuExpanded}>
          <Icon
            name={isSideMenuExpanded ? 'chevronleft' : 'chevronright'}
            width={12}
            onMouseEnter={toggleSideMenuExpanded}
            onTouchStart={toggleSideMenuExpanded}
          />
        </MenuToggleWrapper>
        <StyledLogo>
          <Link className="no-underline" to="/">
            <Logo src={MojecLogo} name="Mojec" width={40} height={34} />
          </Link>
          {isSideMenuExpanded && <CurrentUser onClick={() => setShowUserProfileModal(true)} />}
        </StyledLogo>

        <StyledActionArea aria-expanded={isSideMenuExpanded}>
          <div className="profile">
            <CurrentUser
              showNameOnly={isSideMenuExpanded}
              onClick={() => setShowUserProfileModal(true)}
            />
            <CurrentRole maxLength={isSideMenuExpanded ? 20 : 5} />
            {!isPropertyOwner && (
              <CreatePropertyWrapper>
                {isSideMenuExpanded ? (
                  <Button
                    value="Create Property"
                    group="outline"
                    width="100%"
                    size="md"
                    onClick={() => setShowCreatePropertyModal(true)}
                  />
                ) : (
                  <Icon
                    name="viewgridadd"
                    width={22}
                    title="Create Property"
                    onClick={() => setShowCreatePropertyModal(true)}
                  />
                )}
              </CreatePropertyWrapper>
            )}
          </div>
          <PropertiesDropdown />
        </StyledActionArea>

        <div style={{ flexGrow: 1 }}>
          <Navigation
            // you can use your own router's api to get pathname
            activeItemId={matchedNavigationPath()}
            onSelect={({ itemId }) => {
              // maybe push to the route
              navigate(itemId);
            }}
            items={sideMenuItems!}
          />
        </div>

        <SideMenuBottom>
          <span onClick={logout}>
            {isSideMenuExpanded ? 'Logout ' : ''}
            <Icon
              name="logout"
              width={isSideMenuExpanded ? 14 : 20}
              margin={isSideMenuExpanded ? [0, 0, 0, 10] : []}
            />
          </span>
        </SideMenuBottom>
      </StyledSideMenu>

      {showCreatePropertyModal && (
        <CreateProperty onClose={() => setShowCreatePropertyModal(false)} />
      )}

      {showUserProfileModal && (
        <UserProfile show={showUserProfileModal} onClose={() => setShowUserProfileModal(false)} />
      )}
    </>
  );
});

import type { HTMLFormEventHandler, InputChangeEventHandler } from '@/types/form';
import { useState } from 'react';
import Toast from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';
import { Input } from '@/components/inputs';
import Button from '@/components/Button';
import { useForgotPassword } from '@/hooks/useForgotPassword';
import { Grid } from '@/components/Grid';

const ForgotPassword = () => {
  const location = useLocation();
  const { forgotPassword, isMutating } = useForgotPassword();
  const [email, setEmail] = useState('');

  const handleSubmit: HTMLFormEventHandler = (e) => {
    e.preventDefault();

    if (email)
      forgotPassword({ email }).then((response) => {
        if (response) Toast.success(response);
      });
  };

  const handleInputChange: InputChangeEventHandler = ({ name, value }) => {
    if (name === 'email') setEmail(value);
  };

  return (
    <>
      <Grid spacing="lg">
        <h4>Enter Your Email</h4>
        <h6>You will receive an email with a link to reset your password.</h6>
      </Grid>
      <Grid as="form" spacing="sm" onSubmit={handleSubmit}>
        <Input
          label="Email"
          name="email"
          onChange={handleInputChange}
          value={email}
          validate="required|email"
        />
        <Grid alignX="end" spacing="lg">
          <span className="text-sm">
            Back to Sign In? Click{' '}
            <Link to="/auth/signin" state={location.state}>
              here
            </Link>
          </span>
          <Button value="Continue" size="lg" type="submit" loading={isMutating} width={100} />
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;

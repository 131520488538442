import styled from 'styled-components';
import Button from '../Button';
import Modal from './Modal';

const ActionButtons = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
`;

const AlertModalContent = styled.div`
  margin-bottom: 1.5rem;
`;

const AlertModal = ({
  title,
  show,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onCancel,
  children,
  size = 'sm',
  hideActionGroup = false,
}: any) => {
  const handleCancel = () => {
    onCancel?.();
  };
  const handleConfirm = () => {
    onConfirm?.();
  };

  return (
    <Modal title={title} show={show} onClose={handleCancel} size={size}>
      <AlertModalContent>{children}</AlertModalContent>
      {!hideActionGroup && (
        <ActionButtons>
          <Button onClick={handleCancel} group="outline">
            {cancelText}
          </Button>
          <Button onClick={handleConfirm} group="primary">
            {confirmText}
          </Button>
        </ActionButtons>
      )}
    </Modal>
  );
};

export default AlertModal;

import { columnErrorBoundary } from '@/components/ErrorBoundary';
import { Row, flexRender } from '@tanstack/react-table';

export const renderRow = <T,>(row: Row<T>) => (
  <tr key={row.id}>
    {row
      .getVisibleCells()
      .map((cell) =>
        columnErrorBoundary(
          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
        )
      )}
  </tr>
);

export enum MeterColumnId {
  Name = 'name',
  SerialNumber = 'sn',
  PropertyTariffId = 'propertyTariffId',
  Debt = 'debt',
  Units = 'units',
  BillingType = 'meterBillingType',
  Status = 'meterStatus',
  Type = 'type',
  CreatedAt = 'createdAt',
  Edit = 'edit',
}

export enum PaymentColumnId {
  Status = 'status',
  CreatedAt = 'createdAt',
  AmountPaid = 'amountPaid',
  Units = 'units',
  Initiator = 'initiator',
  TransactionRef = 'txref',
  Channel = 'channel',
  Token = 'token',
}

export enum CustomerColumnId {
  FullName = 'fullName',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  Location = 'location',
  CreatedAt = 'createdAt',
  Edit = 'edit',
}

export enum UserColumnId {
  FullName = 'fullName',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Role = 'role',
  Edit = 'edit',
}

export enum WalletColumnId {
  Name = 'name',
  WalletType = 'walletType',
  WalletId = 'walletId',
  Balance = 'balance',
  MerchantKey = 'merchantKey',
}

export enum TransactionColumnId {
  Status = 'status',
  CreatedAt = 'createdAt',
  AmountPaid = 'amountPaid',
  Units = 'units',
  Initiator = 'initiator',
  InitiatorPhone = 'initiatorPhone',
  TransactionRef = 'txref',
  Channel = 'channel',
  Token = 'token',
}

export enum IntermediateTransactionColumnId {
  IntermediatePaymentId = 'intermediatePaymentId',
  Status = 'status',
  CreatedAt = 'createdAt',
  Amount = 'amount',
  Units = 'units',
  CustomerId = 'customerId',
  TransactionRef = 'providerTxnRef',
  RawData = 'rawData',
}

export enum AdaptedAuditTrailColumnId {
  AuditTrailId = 'auditTrailId',
  ActorId = 'actorId',
  Resource = 'resource',
  Action = 'action',
  Automated = 'automated',
  PreviousValue = 'previousValue',
  NewValue = 'newValue',
  MetaData = 'metaData',
  Api = 'api',
  CreatedAt = 'createdAt',
}

export enum DebtLogColumnId {
  DebtType = 'debtType',
  DebtDelta = 'debtDelta',
  PreviousNetDebt = 'previousNetDebt',
  NewNetDebt = 'newNetDebt',
  CreatedAt = 'createdAt',
}

export enum ScheduledDebtColumnId {
  DebtType = 'debtType',
  DebtAmount = 'debtAmount',
  DebtInterval = 'debtInterval',
  CreatedAt = 'createdAt',
  Delete = 'delete',
}

export enum PropertyColumnId {
  Role = 'role',
  Name = 'name',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Address = 'address',
  SubaccountId = 'subaccountId',
  Vat = 'vat',
  SystemFlatCharge = 'systemFlatCharge',
  RemmitalPercentage = 'remmitalPercentage',
  EstateMaxFlatCharge = 'estateMaxFlatCharge',
  Tags = 'tags',
  CreatedAt = 'createdAt',
}

export enum PropertyTariffColumnId {
  Name = 'name',
  Tariff = 'tariff',
  CreatedAt = 'createdAt',
  Edit = 'edit',
}

import { Property } from '@/graphql/types/Property';
import { roleAdapter } from './roleAdapter';

export type AdaptedProperty = ReturnType<typeof propertyAdapter>;

export const propertyAdapter = (property: Property) => ({
  url: property.url ?? '',
  vat: property.vat ?? '',
  logo: property.logo ?? '',
  name: property.name,
  about: property.about ?? '',
  email: property.email as string,
  address: property.address ?? '',
  createdAt: property.created_at,
  creatorId: property.creator_id,
  propertyId: property.property_id,
  description: property.description ?? '',
  phoneNumber: property.phone_number ?? '',
  propertyCode: property.property_code,
  subaccountId: property.subaccount_id,
  enforceKrnUpdate: property.enforce_krn_update,
  systemFlatCharge: property.system_flat_charge as number,
  remmitalPercentage: property.remmital_percentage as number,
  estateMaxFlatCharge: property.estate_max_flat_charge as number,
  defaultTariffId: property.default_tariff_id,
  role: property.role ? roleAdapter(property.role) : null,
});

import { Modal as BModal } from 'react-bootstrap';
import Icon from '../Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ show, onClose, title, children, footer, centered = true, size }: any) => {
  return (
    <>
      <BModal show={show} centered={centered} backdrop onHide={onClose} size={size}>
        <BModal.Header>
          {title && <BModal.Title style={{ fontWeight: 300 }}>{title}</BModal.Title>}
          <FontAwesomeIcon
            icon={faTimes}
            size="xl"
            style={{ cursor: 'pointer', position: 'absolute', top: 8, right: 8 }}
            onClick={onClose}
          />
        </BModal.Header>
        <BModal.Body className="py-0 overflow-auto max-h-[calc(100vh_-_200px)]">
          <div className="py-4">{children}</div>
        </BModal.Body>
        {footer && <BModal.Footer />}
      </BModal>
    </>
  );
};

export default Modal;

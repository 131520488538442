export const Sizes = {
  xs: 2,
  sm: 4,
  md: 8,
  lg: 16,
  xl: 32,
};

export const Direction = {
  horizontal: 'row',
  '-horizontal': 'row-revers',
  vertical: 'column',
  '-vertical': 'column-reverse',
};

export const Align = {
  end: 'flex-end',
  start: 'flex-start',
  top: 'flex-start',
  bottom: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
  stretch: 'stretch',
};

import { AdaptedProperty } from '@/adapters/propertyAdapter';
import { useAppDispatch } from '@/store/hooks';
import { propertyActions } from '@/store/slices';
import { Link } from 'react-router-dom';

type PropertyNameColumn = {
  property: AdaptedProperty;
};
export const PropertyNameColumn = ({ property }: PropertyNameColumn) => {
  const dispatch = useAppDispatch();

  return (
    <Link
      to={`${property.propertyId}/transactions`}
      state={{ property }}
      onClick={() => dispatch(propertyActions.setActivePropertyId(property.propertyId))}
      className="transition-all hover:text-blue-900 no-underline"
    >
      {property.name}
    </Link>
  );
};

import { useState } from 'react';
import useSWRHook, { SWRResponse } from 'swr';
import { DocumentNode } from 'graphql';
import { getQueryKey } from '@/utilities/getQueryKey';
import { enhancedFetcher } from './utilities/enhancedFetcher';
import { Key } from './types';

/**
 * checks whether the initial variables has changed
 * @param initialVariable
 * @param variables
 * @returns
 */
const checkInitialVariables = <V extends { [key: string]: any }>(
  initialVariables: V | undefined,
  variables: V | undefined
) => {
  if (!initialVariables) return;
  if (!variables) return initialVariables;

  const isInitialVariablesDifferent = Object.entries(initialVariables).some(
    ([p, v]) => variables[p] !== undefined && variables[p] !== v
  );

  return isInitialVariablesDifferent;
};

const getVariables = <V extends { [key: string]: any }>(key: Key<V> | null) =>
  Array.isArray(key) ? key[1] : undefined;

const getVariableKey = <V extends { [key: string]: any }>(
  key: Key<V> | null,
  variables: V | undefined
) => {
  const initialVariables = getVariables(key);
  const isInitialVariablesDifferent = checkInitialVariables(initialVariables, variables);

  return Array.isArray(key)
    ? ([key[0], isInitialVariablesDifferent ? initialVariables : variables] as const)
    : key;
};

export const useSWR = <T, V extends { [key: string]: any } = any>(key: Key<V> | null) => {
  const [variables, setVariables] = useState<V | undefined>(getVariables(key));
  const variableKey = getVariableKey(key, variables);

  const swr = useSWRHook(getQueryKey(variableKey), enhancedFetcher(variableKey), {
    shouldRetryOnError: false,
  }) as SWRResponse<T | undefined, { message: string }[] | undefined>;

  return { ...swr, refresh: swr.mutate, mutate: setVariables };
};

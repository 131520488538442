import { HeaderGroup, flexRender } from '@tanstack/react-table';

export const renderHeader = <T,>(headerGroup: HeaderGroup<T>) => (
  <tr key={headerGroup.id}>
    {headerGroup.headers.map((header) => (
      <th
        key={header.id}
        colSpan={header.colSpan}
        style={{ width: header.column.getSize() !== 150 ? header.column.getSize() : undefined }}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </th>
    ))}
  </tr>
);

import { SyntheticEvent, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Autocomplete as MAutocomplete } from '@material-ui/lab';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { validator } from '../../utilities/helper';
import { Container, StyledHint } from './styled';
import { ErrorInfo } from './components/ErrorInfo';


export const Input = ({
  type = 'text',
  name,
  hint,
  label,
  value,
  placeholder = '',
  disabled = false,
  defaultValue = '',
  onChange,
  validate,
  accept = '',
  resetInput,
  suffixIcon,
  style,
  styleContainer,
}: any) => {
  const [data, setData]: any = useState(value ?? defaultValue);
  const [errors, setErrors]: any = useState([]);
  const hasError = errors.length > 0;

  useEffect(() => {
    if (resetInput) {
      setData('');
      setErrors([]);
    }
  }, [resetInput]);

  const handleValidation = (e: SyntheticEvent) => {
    const { value: v, files: fs }: any = e.target;
    setErrors(validate ? validator(type === 'file' ? fs : v, validate) : []);
  };

  const handleOnChange = (e: SyntheticEvent) => {
    const { name: n, value: v, files: fs }: any = e.target;
    setData(v);

    if (onChange) {
      onChange({ name: n, value: type === 'file' ? fs : v });
    }
  };

  return (
    <Container style={styleContainer}>
      {label && (
        <label
          htmlFor={name}
          className={clsx(
            'mb-2 text-sm font-medium text-gray-900 flex justify-between items-center gap-4',
            {
              'opacity-60': disabled,
            }
          )}
        >
          {label}
          <ErrorInfo errors={errors} />
        </label>
      )}
      <div className="relative">
        <input
          id={name}
          name={name}
          type={type}
          value={data}
          className={clsx(
            'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xs focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5',
            {
              'border-red-600': hasError,
              'pr-8': suffixIcon,
              '!cursor-not-allowed opacity-60': disabled,
            }
          )}
          accept={accept}
          placeholder={placeholder || label}
          disabled={disabled}
          onChange={handleOnChange}
          onBlur={handleValidation}
          required={!!validate}
          style={style}
        />
        {suffixIcon && (
          <span className="absolute top-1/2 -translate-y-1/2 right-1 w-6 max-h-8">
            {suffixIcon}
          </span>
        )}
      </div>
      {hint && <StyledHint>{hint}</StyledHint>}
    </Container>
  );
};

export const PasswordInput = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      suffixIcon={
        showPassword ? (
          <VisibilityOff
            className="cursor-pointer text-gray-500"
            onClick={() => setShowPassword(false)}
          />
        ) : (
          <Visibility
            className="cursor-pointer text-gray-500"
            onClick={() => setShowPassword(true)}
          />
        )
      }
    />
  );
};

export const Select = ({
  resetInput,
  label,
  placeholder = '',
  disabled = false,
  hint,
  value = '',
  name,
  validate,
  onChange,
  defaultValue = '',
  options = [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
  ],
  styleContainer = {},
}: any) => {
  const [data, setData]: any = useState(value || defaultValue);
  const [errors, setErrors]: any = useState([]);
  const hasError = errors.length > 0;

  useEffect(() => {
    if (resetInput) {
      setData('');
      setErrors([]);
    }
  }, [resetInput]);

  const handleValidation = (e: SyntheticEvent) => {
    const { value: v }: any = e.target;
    setErrors(validate ? validator(v, validate) : []);
  };

  const handleOnChange = (e: SyntheticEvent) => {
    const { name: n, value: v }: any = e.target;
    setData(v);
    if (onChange) {
      onChange({ name: n, value: v });
    }
  };

  return (
    <Container style={styleContainer}>
      {label && (
        <label
          htmlFor={name}
          className="mb-2 text-sm font-medium text-gray-900 flex justify-between items-center gap-4"
        >
          {label}
          <ErrorInfo errors={errors} />
        </label>
      )}
      <select
        id={name}
        name={name}
        disabled={disabled}
        onChange={handleOnChange}
        onBlur={handleValidation}
        required={!!validate}
        value={data}
        className={clsx(
          'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5',
          { 'border-red-600': hasError }
        )}
      >
        {placeholder && <option value="">- {placeholder}</option>}
        {options.map(({ value, label }: any) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      {hint && <StyledHint>{hint}</StyledHint>}
    </Container>
  );
};

export const Autocomplete = ({
  resetInput,
  label,
  placeholder = '',
  disabled = false,
  variant = 'outlined',
  hint,
  value = '',
  size = 'small',
  name,
  validate,
  onChange,
  defaultValue = '',
  options = [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
  ],
  styleContainer = {},

  ...rest
}: any) => {
  const [errors, setErrors]: any = useState([]);
  const [data, setData]: any = useState({
    value: value || defaultValue,
    inputValue: value || defaultValue,
    selectValue: value || defaultValue,
  });

  useEffect(() => {
    if (resetInput) {
      setData({
        value: '',
        inputValue: '',
        selectValue: '',
      });
      setErrors([]);
    }
  }, [resetInput]);

  const handleOnChange = (e: SyntheticEvent, t: any) => {
    if (t) {
      setData({ value: t.value, inputValue: t.label, selectValue: t.label });
      if (onChange) {
        onChange({ name, value: t.value });
      }
    }
  };

  const handleOnInputChange = (e: SyntheticEvent, p: any) => {
    if (e) setData({ value: '', inputValue: p, selectValue: '' });
  };

  return (
    <Container style={styleContainer}>
      <FormControl
        defaultValue={defaultValue}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
      >
        <MAutocomplete
          freeSolo
          onChange={handleOnChange}
          onInputChange={handleOnInputChange}
          error={errors.length > 0 ? errors[0] : ''}
          getOptionLabel={(option: any) => option.label ?? option}
          required={!!validate}
          style={{ minWidth: 160, maxWidth: 220 }}
          options={options}
          size={size}
          disableListWrap
          disablePortal
          value={data.selectValue}
          inputValue={data.inputValue}
          renderInput={(params: any) => (
            <>
              <input type="hidden" name={name} value={data.value} />
              <Input
                {...params}
                variant={variant}
                label={label}
                placeholder={placeholder ?? label}
                disabled={disabled}
                id={name}
              />
            </>
          )}
          // renderOption={(option: any) => <MenuItem value={option.value}>{option.label}</MenuItem>}
          {...rest}
        />
        {errors.length > 0 && (
          <FormHelperText style={{ color: '#f44336' }}>
            {errors.length > 0 ? errors[0] : ''}
          </FormHelperText>
        )}
      </FormControl>
      {hint && <StyledHint>{hint}</StyledHint>}
    </Container>
  );
};

export const Checkbox = ({
  id,
  name,
  type = 'checkbox',
  hint,
  label,
  value,
  onChange,
  validate,
  disabled = false,
}: any) => {
  const handleOnChange = (e: SyntheticEvent) => {
    const { name: n, checked: v }: any = e.target;
    if (onChange) {
      onChange({ name: n, value: v });
    }
  };

  return (
    <div className="flex">
      <div className="flex" style={{ paddingTop: 4 }}>
        <input
          id={id || name}
          type={type}
          name={name}
          value={value}
          onChange={handleOnChange}
          required={!!validate}
          disabled={disabled}
          className={clsx('w-4 h-4 text-blue-600 bg-gray-100 border-gray-300', {
            'rounded-md': type === 'checkbox',
          })}
        />
      </div>
      <div className="ml-2">
        {label && (
          <label
            htmlFor={id || name}
            className={clsx('ml-0 text-sm font-medium text-gray-900', {
              'text-gray-500': disabled,
            })}
          >
            {label}
          </label>
        )}
        {hint && (
          <p id={`${id || name}-hint`} className="text-xs font-normal text-gray-500">
            {hint}
          </p>
        )}
      </div>
    </div>
  );
};

export const Radio = (props: any) => <Checkbox {...props} type="radio" />;


import gql from 'graphql-tag';

export const $ROLE$ = gql`
  fragment Role on Role {
    role_id
    slug
    title
    created_at
    updated_at
  }
`;

export const $WALLET$ = gql`
  fragment Wallet on Wallet {
    email
    name
    wallet_id
    organization_id
    merchant_key
    wallet_type
    balance
    created_at
  }
`;

export const $TRX_TOKEN$ = gql`
  fragment TrxToken on TransactionToken {
    token_dec
    token_hex
    subclass
    vend_time_unix
    unit_name
    sn
    ea
    krn
    sgc
    tct
    ti
    ken
    raw_response
  }
`;

export const $TRX_BREAKDOWN$ = gql`
  fragment TrxBreakdown on TransactionBreakdown {
    total_amount
    merchant_charge_percent
    merchant_charge_flat
    vat_percent
    merchant_total_amount
    principal_after_merchant_total
    vat_amount
    principal_after_vat
    debt_amount
    debt_cleared_amount
    debt_left_amount
    principal_after_debt
    value_amount
    flutterwave_fee
    stamp_duty_fee
    true_total_amount
  }
`;

export const $ALERT_EMAILS$ = gql`
  fragment AlertEmail on AlertEmail {
    id
    email
  }
`;

export const $PROPERTY_SETTINGS$ = gql`
  fragment PropertySettings on PropertySettings {
    report {
      daily
      weekly
      monthly
      yearly
      throttle
    }
  }
`;

export const $PROPERTY$ = gql`
  fragment Property on Property {
    property_id
    creator_id
    email
    name
    phone_number
    property_code
    address
    url
    logo
    about
    description
    subaccount_id
    remmital_percentage
    vat
    enforce_krn_update
    estate_max_flat_charge
    system_flat_charge
    default_tariff_id
    role {
      slug
      title
      created_at
      updated_at
    }
    created_at
  }
`;

export const $PROPERTY_TARIFF$ = gql`
  fragment PropertyTariff on PropertyTariff {
    property_id
    property_tariff_id
    name
    tariff
    created_at
    updated_at
  }
`;

export const $METER$ = gql`
  fragment Meter on Meter {
    meter_id
    meter_code
    property_id
    name
    sn
    address
    info
    data_opt
    meter_tariff
    meter_type
    meter_billing_type
    comms
    debt
    units
    type
    ea
    tct
    sgc
    ti
    krn
    ken
    is_enabled
    has_camera
    created_at
    updated_at
    property_tariff_id
  }
`;

export const $METER_PROPERTY_TARIFF$ = gql`
  ${$PROPERTY_TARIFF$}
  fragment MeterPropertyTariff on Meter {
    property_tariff_id
    property_tariff {
      ...PropertyTariff
    }
  }
`;

export const $PAYMENT$ = gql`
  ${$TRX_BREAKDOWN$}
  ${$TRX_TOKEN$}
  fragment Payment on Payment {
    payment_id
    property_id
    meter_id
    units
    amount_paid
    payment_status
    raw_response
    created_at
    updated_at
    breakdown {
      ...TrxBreakdown
    }
    token_data {
      ...TrxToken
    }
  }
`;

export const $CUSTOMER$ = gql`
  fragment Customer on Customer {
    customer_id
    email
    firstname
    lastname
    phone_number
    location
    created_at
    updated_at
  }
`;

export const $PROPERTY_USER$ = gql`
  fragment PropertyUser on User {
    user_id
    email
    firstname
    lastname
    phone_number
    picture
    location
    role {
      role_id
      slug
      title
      created_at
      updated_at
    }
  }
`;

export const $USER$ = gql`
  fragment User on User {
    user_id
    email
    firstname
    lastname
    phone_number
    token
    picture
    location
    properties {
      property_id
    }
  }
`;

export const $S3FILE$ = gql`
  fragment S3File on S3File {
    url
  }
`;

export const $PAGINATION$ = gql`
  fragment Pagination on Pagination {
    page
    per_page
    total_pages
    total_items
  }
`;

export const $PERMISSIONS$ = gql`
  fragment Permission on Permission {
    permission_id
    title
    slug
    created_at
    updated_at
  }
`;

export const $PROPERTY_PERMISSIONS$ = gql`
  fragment PropertyPermission on PropertyPermission {
    permission_id
    title
    slug
    created_at
    updated_at
  }
`;

export const $DEBT_LOG$ = gql`
  fragment DebtLog on DebtLog {
    debt_log_id
    property_id
    meter_id
    creator_id
    debt_type
    debt_delta
    previous_net_debt
    new_net_debt
    updated_at
    created_at
  }
`;

export const $SCHEDULED_DEBT$ = gql`
  fragment ScheduledDebt on ScheduledDebt {
    scheduled_debt_id
    property_id
    meter_id
    creator_id
    debt_type
    debt_amount
    debt_interval
    updated_at
    created_at
  }
`;

export const $SUCCESS_RESULT$ = gql`
  fragment SuccessResult on SuccessResult {
    success
  }
`;

export const $INTERMEDIATE_PAYMENT$ = gql`
  fragment IntermediatePayment on IntermediatePayment {
    intermediate_payment_id
    property_id
    meter_id
    disco_meter_id
    customer_id
    units
    amount
    raw_data
    provider_txn_ref
    created_at
    updated_at
    has_linked_payment
    payment {
      payment_id
    }
  }
`;

export const $AUDIT_TRAIL$ = gql`
  fragment AuditTrail on AuditTrail {
    audit_trail_id
    actor_id
    property_id
    resource
    resource_ids
    action
    automated
    previous_value
    new_value
    meta_data
    api_resource_path
    api_method
    created_at
  }
`;

import type { AdaptedProperty } from '@/adapters/propertyAdapter';
import { Badge } from '@/components/Badge';
import { useMemo } from 'react';

export enum TagsEnum {
  KRN = 'KRN',
}

const getTags = (property: AdaptedProperty) => {
  const tags = [];

  if (property.enforceKrnUpdate) {
    tags.push({ name: TagsEnum.KRN, color: '' });
  }

  return tags;
};

type TagsProps = { property: AdaptedProperty };

export const Tags = ({ property }: TagsProps) => {
  const tags = useMemo(() => getTags(property), [property]);
  return (
    <div className="flex gap-1 flex-wrap">
      {tags.map((tag) => (
        <Badge key={tag.name} label={tag.name} color={tag.color} />
      ))}
    </div>
  );
};

import styled from 'styled-components';
import { Button as BButton } from 'react-bootstrap';
import Loader from './Loader';
import Icon from './Icon';

const StyledButton = styled.div`
  ${({ center }: any) => (center ? `display: flex; justify-content: center;` : '')};

  button {
    font-weight: 500;
    font-size: 13px;
    margin: ${(props: any) => props.margin};
    padding: ${(props: any) => props.padding || '0 15px'};
    height: ${(props: any) =>
      props.size === 'lg'
        ? '42px'
        : props.size === 'sm'
        ? '26px'
        : props.size === 'md'
        ? '38px'
        : '32px'};
    width: ${({ width }: any) =>
      width ? (typeof width === 'string' ? width : `${width}px`) : 'auto'};
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  button .content,
  button .hidden-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  button .hidden-content {
    visibility: hidden;
  }

  button.primary {
    background: #2758a2;
    color: #ffffff;
  }

  button.secondary {
    background: #dce8f4;
    color: #2758a2;
  }

  button.outline {
    background: #ffffff;
    border: 1px solid #2758a2;
    color: #2758a2;
    outline-style: none;
  }

  button.outline:hover {
    background: #dce8f4;
  }

  button.round {
    border-radius: 16px;
  }

  button > .loading {
    position: absolute;
  }
`;

const Button = ({
  type = 'button',
  group = 'primary',
  icon,
  value,
  children,
  size,
  width,
  margin = [],
  padding = [],
  center,
  loading,
  disabled,
  style,
  buttonStyles = {},
  title,
  ...rest
}: any) => {
  return (
    <StyledButton
      {...{
        size,
        width,
        center,
        margin: margin.length > 0 && `${margin.join('px ')}px;`,
        padding: padding.length > 0 && `${padding.join('px ')}px;`,
      }}
      style={style}
    >
      <BButton
        type={type}
        className={group}
        style={buttonStyles}
        disabled={loading || disabled}
        title={title}
        {...rest}
      >
        <>
          <span className={loading ? 'hidden-content' : 'content'}>
            {icon && (
              <Icon
                name={icon}
                style={{ fontSize: 16, marginRight: value || children ? 5 : 0 }}
                width={14}
                onClick={() => null}
              />
            )}
            {value || children}
          </span>
          {loading && (
            <div className="loading">
              <Loader color="white" />
            </div>
          )}
        </>
      </BButton>
    </StyledButton>
  );
};

export default Button;

import type { PayloadAction } from '@reduxjs/toolkit';
import type { AdaptedUser } from '@/adapters/userAdapter';
import type { UserState } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { Slice } from '../types';
import { saveUser } from '@/store/slices/utils/saveUser';
import { localClear } from '@/utilities/forage';

const initialState: UserState = {
  user: null,
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: Slice.User,
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AdaptedUser>) => {
      const user = action.payload;
      //   const activePropertyId = user?.propertiesId?.[0];
      saveUser(user);
      state.user = user;
      state.isLoggedIn = Boolean(user.token);
    },
    logout: (state) => {
      localClear();
      state = initialState;
      window.location.replace('/#/auth/signin');
    },
    updateUser: (state, action: PayloadAction<AdaptedUser>) => {
      const user = { ...state.user, ...action.payload };
      saveUser(user);
      state.user = user;
    },
  },
});

// Action creators are generated for each case reducer function
export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;

import type { HTMLFormEventHandler, InputChangeEventHandler } from '@/types/form';
import { useEffect, useState } from 'react';
import Toast from 'react-hot-toast';
import { PasswordInput } from '@/components/inputs';
import Button from '@/components/Button';
import useSearchParams from '@/router/useSearchParams';
import { useResetPassword } from '@/hooks/useResetPassword';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@/components/Grid';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { message, resetPassword, isMutating } = useResetPassword();
  const [password, setPassword] = useState('');
  const searchParams = useSearchParams();
  const reset_token = searchParams.get('resetCode');

  const handleSubmit: HTMLFormEventHandler = (e) => {
    e.preventDefault();

    if (reset_token) resetPassword({ reset_token, password });
  };

  const handleInputChange: InputChangeEventHandler = ({ name, value }) => {
    if (name === 'password') setPassword(value);
  };

  useEffect(() => {
    if (message) {
      Toast.success(message);
      navigate('/auth/signin');
    }
  }, [message]);

  return (
    <>
      <Grid spacing="lg">
        <h4>Reset Password</h4>
        <h6>Hey, enter your new password ONCE below to reset your password.</h6>
      </Grid>
      <Grid as="form" spacing="lg" onSubmit={handleSubmit}>
        <PasswordInput
          label="New Password"
          name="password"
          onChange={handleInputChange}
          value={password}
          validate="required"
        />
        <Grid alignX="end">
          <Button value="Reset Password" size="lg" type="submit" loading={isMutating} width={140} />
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;

import type { ReactNode } from 'react';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@/components/Grid';

export const renderEmptyRow = <T,>(noData?: ReactNode, noDataText?: string) => (
  <tr key="no-data-row" className="!bg-white">
    <td key="no-data-cell" colSpan={100}>
      <Grid spacing="lg" alignX="center" alignY="center" className="p-8">
        {noData || (
          <>
            <FontAwesomeIcon icon={faFolderOpen} size="4x" color="#2758a2" />
            <span className="text-md">{noDataText ?? 'Nothing to see here'}</span>
          </>
        )}
      </Grid>
    </td>
  </tr>
);

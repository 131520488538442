import type {
  userPropertiesWithRole_userPropertiesWithRole,
  userPropertiesWithRoleVariables,
} from '@/graphql/types/userPropertiesWithRole';
import { $GET_PROPERTIES_WITH_ROLE } from '@/graphql/queries';
import { useSWR } from '../useSWR';
import { NonNullableArray } from '@/types/utils';
import { propertyAdapter } from '@/adapters';
import { paginationAdapter } from '@/adapters/paginationAdapter';

export const userPropertiesWithRole = (initialPage?: userPropertiesWithRoleVariables) => {
  const { data, error, mutate, isLoading } = useSWR<
    userPropertiesWithRole_userPropertiesWithRole,
    userPropertiesWithRoleVariables
  >([$GET_PROPERTIES_WITH_ROLE, initialPage ?? {}]);
  const properties = data
    ? (data.data as NonNullableArray<typeof data.data>)?.map(propertyAdapter)
    : undefined; // adapt data here

  const page = data ? paginationAdapter(data.pagination) : undefined;

  return {
    page,
    error,
    properties,
    isLoading,
    hasError: Boolean(error),
    fetchProperties: mutate,
  };
};

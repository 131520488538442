import styled from 'styled-components';
import { Table as BTable, TableProps } from 'react-bootstrap';

export const Table = styled(BTable)<
  TableProps & { alignY: 'top' | 'middle' | 'bottom' | 'baseline' }
>`
  height: inherit;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 0px 40px 0px rgb(0 0 0 / 15%);
  position: relative;

  ._loading_overlay_overlay {
    margin-top: 0 !important;
    height: 100% !important;
    color: #000000 !important;
    background-color: #ffffff !important;
  }

  .react-bootstrap-table table {
    table-layout: auto !important;
  }

  .react-bootstrap-table-pagination {
    display: none !important;
  }

  th,
  td {
    padding-left: 20px;
  }

  &.table > :not(caption) > * > * {
    border-bottom-width: 0 !important;
  }

  ${({ align = 'top' }) => `
    td {
      vertical-align: ${align};
    }
  `}

  thead {
    color: #082f67;
    background: #f8f9fa;
    box-shadow: 0 5px 20px 0px rgb(0 0 0 / 10%);

    th {
      padding-top: 13px;
      padding-bottom: 13px;
      font-weight: 600;
      font-size: 13px;
      border: none;
      position: sticky;
      top: 0;
      z-index: 10;
      background: #f8f9fa;
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid #dddddd;
      border-right: 3px solid transparent;
      border-left: 3px solid transparent;
    }

    .dropup,
    .dropdown {
      position: relative;
    }

    .dropup .caret,
    .navbar-fixed-bottom .dropdown .caret {
      border-top: 0;
      border-bottom: 4px dashed;
      border-bottom: 4px solid #dddddd;
      content: '';
    }
  }

  ${({ borderless }) => (borderless ? 'td { border: none !important; }' : '')}

  tbody {
    background: #ffffff;

    tr {
      transition: background 300ms ease;

      &:first-child td {
        border-top: none;
      }

      &:hover {
        background-color: #f8f9fa;
      }

      td {
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 12px;
        font-weight: 300;
        border-top: 1px solid #f4f4f4;
      }
    }
  }
`;

import type { updateUser_updateUser, updateUserVariables } from '@/graphql/types/updateUser';
import { $UPDATE_USER } from '@/graphql/mutations';
import { useSWRMutate } from '../useSWR';
import { userAdapter } from '@/adapters';

export const useUpdateUser = () => {
  const { data, error, trigger, isMutating } = useSWRMutate<
    updateUser_updateUser,
    updateUserVariables
  >($UPDATE_USER);

  const user = data ? userAdapter(data) : undefined;

  return {
    error,
    user,
    isError: Boolean(error),
    isMutating,
    updateUser: trigger,
  };
};

import { propertyAdapter } from '@/adapters';
import { $GET_PROPERTY } from '@/graphql/queries';
import type {
  singlePropertyVariables,
  singleProperty_singleProperty,
} from '@/graphql/types/singleProperty';
import { useSWR } from '@/hooks/useSWR';

export const useProperty = (property_id?: string) => {
  const { data, error, mutate, isLoading } = useSWR<
    singleProperty_singleProperty,
    singlePropertyVariables
  >(property_id ? [$GET_PROPERTY, { property_id }] : null);

  const property = data ? propertyAdapter(data) : undefined; // adapt data here

  return {
    property,
    isLoading,
    error,
    hasError: Boolean(error),
    mutate,
  };
};

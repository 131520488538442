import { configureStore } from '@reduxjs/toolkit';
import { Slice } from './slices/types';
import { userReducer, propertyReducer } from './slices';
import { appSettingsReducer } from './slices/appSettings';

export const store = configureStore({
  reducer: {
    [Slice.User]: userReducer,
    [Slice.Property]: propertyReducer,
    [Slice.AppSettings]: appSettingsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

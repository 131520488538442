import { ReactNode, Suspense as ReactSuspense } from 'react';
import Loader from '@/components/Loader';
import { ErrorBoundary } from '@/components/ErrorBoundary';

export const Suspense: React.FC<{ element?: ReactNode; children?: ReactNode }> = ({
  children,
  element,
}) => (
  <ReactSuspense fallback={<Loader absolute location={document.querySelector('main')!} />}>
    <ErrorBoundary>{children ?? element}</ErrorBoundary>
  </ReactSuspense>
);

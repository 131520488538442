import type { signinVariables, signin_signin } from '@/graphql/types/signin';
import { $SIGN_IN } from '@/graphql/mutations';
import { useSWRMutate } from '../useSWR';
import { userAdapter } from '@/adapters';

export const useLogin = () => {
  const { data, error, trigger, isMutating } = useSWRMutate<signin_signin, signinVariables>(
    $SIGN_IN
  );

  const user = data ? userAdapter(data) : undefined;

  return {
    user,
    error,
    isError: Boolean(error),
    isMutating,
    login: trigger,
  };
};

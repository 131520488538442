import type {
  userProperties_userProperties,
  userPropertiesVariables,
} from '@/graphql/types/userProperties';
import { $GET_PROPERTIES } from '@/graphql/queries';
import { useSWR } from '../useSWR';
import { propertyAdapter } from '@/adapters';

export const userProperties = () => {
  const { data, error, mutate, isLoading } = useSWR<
    userProperties_userProperties[],
    userPropertiesVariables
  >([$GET_PROPERTIES, {}]);
  const properties = data?.map(propertyAdapter);

  return {
    error,
    properties,
    isLoading,
    hasError: Boolean(error),
    fetchProperties: mutate,
  };
};

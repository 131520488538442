import * as Sentry from '@sentry/react';

export const ErrorBoundary = ({ children, ...props }: Sentry.ErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    fallback={<h1>Something went wrong.! please refresh the page</h1>}
    {...props}
  >
    {children}
  </Sentry.ErrorBoundary>
);

import type { forgotPasswordVariables } from '@/graphql/types/forgotPassword';
import { useSWRMutate } from '../useSWR';
import { $FORGOT_PASSWORD } from '@/graphql/queries';

export const useForgotPassword = () => {
  const { data, error, trigger, isMutating } = useSWRMutate<string, forgotPasswordVariables>(
    $FORGOT_PASSWORD
  );

  return {
    error,
    message: data,
    isError: Boolean(error),
    isMutating,
    forgotPassword: trigger,
  };
};

import { useState, useEffect, SyntheticEvent } from 'react';
import clsx from 'clsx';
import ReactPI, { PhoneInputProps } from 'react-phone-input-2';
import { validator } from '@/utilities/helper';
import { ErrorInfo } from './components/ErrorInfo';
import { Container, StyledHint } from './styled';
import 'react-phone-input-2/lib/style.css';

const ReactPhoneInput: React.FC<PhoneInputProps> = (ReactPI as any).default ?? ReactPI;

export const PhoneInput = ({
  resetInput,
  label,
  placeholder = '',
  disabled = false,
  type = 'text',
  variant = 'outlined',
  hint,
  value,
  name,
  size = 'small',
  defaultValue = '',
  onChange,
  validate,
  defaultCountry = 'ng',
  ...rest
}: any) => {
  const [data, setData]: any = useState(value ?? defaultValue);
  const [errors, setErrors]: any = useState([]);
  const hasError = errors.length > 0;

  useEffect(() => {
    if (resetInput) {
      setData('');
      setErrors([]);
    }
  }, [resetInput]);

  const handleValidation = (e: SyntheticEvent, ...a: any) => {
    const { value: v }: any = e.target;
    setErrors(validate ? validator(v?.replaceAll(' ', ''), validate) : []);
  };

  const handleOnChange = (v: string, { dialCode }: any) => {
    setData(v);

    if (onChange) {
      onChange({ name, value: `+${dialCode} ${v.replace(`${dialCode}`, '')}` });
    }
  };

  return (
    <Container>
      {label && (
        <label
          htmlFor={name}
          className="mb-2 text-sm font-medium text-gray-900 flex justify-between items-center gap-4"
        >
          {label}
          <ErrorInfo errors={errors} />
        </label>
      )}
      <div className="relative">
        <ReactPhoneInput
          country={defaultCountry}
          countryCodeEditable={false}
          size={size}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          variant={variant}
          aria-label={label}
          aria-describedby={label}
          value={data}
          onChange={handleOnChange}
          onBlur={handleValidation}
          required={!!validate}
          style={{ maxHeight: 38 }}
          inputClass={clsx(
            '!bg-gray-50 border rounded-lg border-gray-300 text-gray-900 text-sm rounded-xs focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5',
            { 'border-red-600': hasError }
          )}
          {...rest}
        />
      </div>
      {hint && <StyledHint>{hint}</StyledHint>}
    </Container>
  );
};

import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

/** Safeguard to prevent a misconfigured env var from disabling auth */
const urlIsDevelopmentRegExp = new RegExp('^https?://(localhost|127.0.0.1)');

export const sentryInit = () => {
  const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
  const urlIsDevelopment =
    urlIsDevelopmentRegExp.test(window.location.origin) || import.meta.env.DEV;

  Sentry.init({
    dsn: SENTRY_DSN,
    enabled: SENTRY_DSN && !urlIsDevelopment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.6, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

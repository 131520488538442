import type { Role } from '@/graphql/types/Role';
import type { User } from '@/graphql/types/user';
import { roleAdapter } from './roleAdapter';

export type AdaptedUser = ReturnType<typeof userAdapter>;

export const userAdapter = <T>(
  user: Omit<User, 'token' | 'properties'> &
    Partial<Pick<User, 'token' | 'properties'>> &
    Partial<{ role: Role | null }>
) => ({
  email: user.email as string,
  token: user.token,
  userId: user.user_id,
  picture: user.picture,
  firstName: user.firstname ?? '',
  lastName: user.lastname ?? '',
  fullName: `${user.firstname ?? ''} ${user.lastname ?? ''}`.trim(),
  location: user.location ?? '',
  phoneNumber: user.phone_number ?? '',
  role: user.role ? roleAdapter(user.role) : null,
  propertiesId: (user.properties ?? []).map(({ property_id }) => property_id),
});

import type { AppSettingsState } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { Slice } from '../types';
import { STORAGE } from '@/utilities/constants';
import { localGet, localSet } from '@/utilities/forage';

const initialState = {
  isSideMenuExpanded: Boolean(localGet(STORAGE.SIDE_MENU_EXPANDED) || false),
} as AppSettingsState;

export const appSettingsSlice = createSlice({
  name: Slice.AppSettings,
  initialState,
  reducers: {
    toggleSideMenuExpanded: (state) => {
      const isSideMenuExpanded = !state.isSideMenuExpanded;
      state.isSideMenuExpanded = isSideMenuExpanded;
      localSet({ key: STORAGE.SIDE_MENU_EXPANDED, data: isSideMenuExpanded ? true : '' });
    },
  },
});

// Action creators are generated for each case reducer function
export const appSettingsActions = appSettingsSlice.actions;
export const appSettingsReducer = appSettingsSlice.reducer;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  height: 100%;
  background: #f2f2f2;
  position: relative;

  main {
    flex: 1 100%;
    position: relative;

    > div#main {
      max-height: 100vh;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;

      > section {
        padding: 20px;
      }
    }
  }
`;

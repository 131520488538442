import styled from 'styled-components';

const StyledLogo = styled.div`
  img {
    height: 30px;
  }
`;

const Logo = ({ src, name = '', width, height = 30 }: any) => {
  return (
    <StyledLogo>
      <img
        src={src}
        alt={name}
        style={{
          width: width ? `${width}px` : 'auto',
          height: height ? `${height}px` : 'auto',
        }}
      />
    </StyledLogo>
  );
};

export default Logo;

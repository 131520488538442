import { Shimmer } from '@/components/Shimmer/Shimmer';
import TruncatedText from '@/components/Truncated';
import { useActiveProperty } from '@/hooks/useActiveProperty';

type CurrentRoleProps = {
  maxLength: number;
};
export const CurrentRole = ({ maxLength }: CurrentRoleProps) => {
  const { activeProperty, isLoading: isPropertyLoading } = useActiveProperty();

  return (
    <div className="role-badge">
      {isPropertyLoading && <Shimmer transparent height="16px" />}
      {!isPropertyLoading && activeProperty?.role?.title && (
        <TruncatedText text={activeProperty.role.title!} maxLength={maxLength} />
      )}
    </div>
  );
};

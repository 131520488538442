import type { TableProps } from './types';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table as BTable } from './styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMemo } from 'react';
import { renderRow } from './utilities/renderRow';
import { renderHeader } from './utilities/renderHeader';
import { renderFooter } from './utilities/renderFooter';
import { renderLoadingRow } from './utilities/renderLoadingRow';
import { renderEmptyRow } from './utilities/renderEmptyRow';

export const Table = <T,>(props: TableProps<T>) => {
  const {
    hideSerialColumn,
    page,
    'data-testid': dataTestId,
    data,
    columns: defaultColumns,
    isLoading = false,
    noOfLoadingRows = 3,
    noData,
    noDataText,
    align = 'top',
    className,
    ...config
  } = props;
  const rowIndexOffset = page ? (page?.page - 1) * page?.perPage + 1 : 1;
  const columns = useMemo(() => {
    if (!hideSerialColumn)
      return [
        {
          id: 'index',
          header: '',
          cell: (info: any) => (
            <span style={{ paddingLeft: 8 }}>{info.row.index + rowIndexOffset}</span>
          ),
        },
        ...defaultColumns,
      ];
    return defaultColumns;
  }, [rowIndexOffset, defaultColumns]);

  const table = useReactTable({
    data,
    columns,
    ...config,
    getCoreRowModel: getCoreRowModel(),
  });

  const flatVisibleColumns = table.getVisibleFlatColumns();
  const { rows } = table.getRowModel();

  return (
    <BTable alignY={align} className={className}>
      <thead>{table.getHeaderGroups().map(renderHeader)}</thead>
      <tbody>
        {!isLoading && rows.length === 0 && renderEmptyRow(noData, noDataText)}
        {!isLoading && rows.length > 0 && rows.map(renderRow)}
        {isLoading &&
          [...Array(noOfLoadingRows).fill(0)].map((_, key) =>
            renderLoadingRow(flatVisibleColumns, key)
          )}
      </tbody>
      <tfoot>{table.getFooterGroups().map(renderFooter)}</tfoot>
    </BTable>
  );
};

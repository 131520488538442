import Icon from '../components/Icon';

export const BASE_URL = 'https://base-url.com';

export const GRAPH_URL = 'https://mojec-vend.etrackbase.com/graphql/v1';

export const STORAGE = {
  USER: 'mojec-user',
  TOKEN: 'mojec-token',
  PROPERTIES: 'mojec-properties',
  ACTIVE_PROPERTY: 'mojec-active-property',
  SIDE_MENU_EXPANDED: 'side-menu-expanded',
};

export const ROUTE = {
  LANDING: { path: '/', meta: { title: 'Landing' }, showSideMenu: true },
  SIGN_IN: { path: 'auth/signin', meta: { title: 'Sign In' } },
  RESET_PASSWORD: { path: 'auth/reset-password', meta: { title: 'Reset Password' } },
  FORGOT_PASSWORD: { path: 'auth/forgot-password', meta: { title: 'Forgot Password' } },
  DASHBOARD: { path: 'dashboard', meta: { title: 'Dashboard' }, showSideMenu: true },
  OVERVIEW: { path: 'overview', meta: { title: 'overview' }, showSideMenu: true },
  TENANTS: { path: 'tenants', meta: { title: 'Tenants' }, showSideMenu: true },
  METERS: { path: 'meters', meta: { title: 'Meters' }, showSideMenu: true },
  TRANSACTIONS: {
    path: 'transactions',
    meta: { title: 'Transactions' },
    showSideMenu: true,
  },
  LOGS: {
    path: 'logs',
    meta: { title: 'Logs' },
    showSideMenu: true,
  },
  AUDIT_LOGS: {
    path: 'logs/audit',
    meta: { title: 'Audit Logs' },
    showSideMenu: true,
  },
  TRANSACTIONS_LOGS: {
    path: 'logs/transactions',
    meta: { title: 'Transactions Log' },
    showSideMenu: true,
  },
  SETTINGS: { path: 'settings', meta: { title: 'Settings' }, showSideMenu: true },
  NOT_FOUND: { path: '404', meta: { title: 'Not Found' } },
};

export const SIDE_MENU = [
  {
    title: 'Meters',
    itemId: ROUTE.METERS.path,
    elemBefore: () => <Icon name="electric-meter-white" width={20} />,
  },
  {
    title: 'Tenants',
    itemId: ROUTE.TENANTS.path,
    elemBefore: () => <Icon name="users-white" width={20} />,
  },
  {
    title: 'Transactions',
    itemId: ROUTE.TRANSACTIONS.path,
    elemBefore: () => <Icon name="transaction" width={20} />,
  },
  {
    title: 'Logs',
    itemId: ROUTE.LOGS.path,
    elemBefore: () => <Icon name="database" width={20} />,
    subNav: [
      {
        title: 'Audit trail',
        itemId: ROUTE.AUDIT_LOGS.path,
        elemBefore: () => <Icon name="cloud-snow" />,
      },
      {
        title: 'Transactions Log',
        itemId: ROUTE.TRANSACTIONS_LOGS.path,
        elemBefore: () => <Icon name="cloud-snow" />,
      },
    ],
  },
  {
    title: 'Settings',
    itemId: ROUTE.SETTINGS.path,
    elemBefore: () => <Icon name="gear" width={20} />,
  },
];

export const BILLING_TYPES = [
  { label: 'Prepaid', value: 'PREPAID' },
  { label: 'Postpaid', value: 'POSTPAID' },
];

export enum DebtType {
  Repairs = 'repairs',
  Maintenance = 'maintenance',
  CustomerDefault = 'customer_default',
  Tamper = 'tamper',
  Penalty = 'penalty',
  Leakages = 'leakages',
  Debt = 'debt',
  Levy = 'levy',
  Dues = 'dues',
  FixedCharge = 'fixed_charge',
  ServiceCharge = 'service_charge',
  DebtRecovery = 'debt_recovery',
}

export const DEBT_TYPE_LABEL_MAP: Record<DebtType, string> = {
  [DebtType.Repairs]: 'Repairs',
  [DebtType.Maintenance]: 'Maintenance',
  [DebtType.CustomerDefault]: 'Customer default',
  [DebtType.Tamper]: 'Tamper',
  [DebtType.Penalty]: 'Penalty',
  [DebtType.Leakages]: 'Leakages',
  [DebtType.Debt]: 'Debt',
  [DebtType.Levy]: 'Levy',
  [DebtType.Dues]: 'Dues',
  [DebtType.FixedCharge]: 'Fixed charge',
  [DebtType.ServiceCharge]: 'Service charge',
  [DebtType.DebtRecovery]: 'Debt recovery',
};

export const DEBT_TYPES = Object.entries(DEBT_TYPE_LABEL_MAP)
  .map(([value, label]) => ({
    value,
    label,
  }))
  .filter((v) => v.value !== DebtType.DebtRecovery);

export enum DebtInterval {
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export const DEBT_INTERVAL_LABEL_MAP: Record<DebtInterval, string> = {
  [DebtInterval.Weekly]: 'Weekly',
  [DebtInterval.Monthly]: 'Monthly',
};

export const DEBT_INTERVALS = Object.entries(DEBT_INTERVAL_LABEL_MAP).map(([value, label]) => ({
  value,
  label,
}));

export const METER_STATUS = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const METER_TYPES = [
  { value: 'SINGLE_PHASE', label: 'Single Phase' },
  { value: 'THREE_PHASE', label: 'Three Phase' },
];

export const PAYMENT_TYPE = [
  { label: 'Card', value: 'card' },
  { label: 'Cash', value: 'cash' },
  { label: 'USSD', value: 'ussd' },
  { label: 'Bank transfer', value: 'bank_transfer' },
];

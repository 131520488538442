import gql from 'graphql-tag';
import {
  $CUSTOMER$,
  $S3FILE$,
  $PAYMENT$,
  $PROPERTY$,
  $USER$,
  $METER$,
  $WALLET$,
  $PAGINATION$,
  $AUDIT_TRAIL$,
  $PROPERTY_USER$,
  $PERMISSIONS$,
  $PROPERTY_PERMISSIONS$,
  $DEBT_LOG$,
  $SCHEDULED_DEBT$,
  $INTERMEDIATE_PAYMENT$,
  $PROPERTY_TARIFF$,
  $METER_PROPERTY_TARIFF$,
} from './fragments';

export const $GET_USER = gql`
  ${$USER$}
  query user {
    user {
      ...User
    }
  }
`;

export const $FORGOT_PASSWORD = gql`
  query forgotPassword($email: EmailAddress!) {
    forgotPassword(email: $email)
  }
`;

export const $GET_METERS = gql`
  ${$METER$}
  ${$PAGINATION$}
  query meters($property_id: ID!, $page: Int, $per_page: Int, $filters: MeterFilters) {
    meters(property_id: $property_id, page: $page, per_page: $per_page, filters: $filters) {
      pagination {
        ...Pagination
      }
      data {
        ...Meter
      }
    }
  }
`;

// Get Meters for a property that have not been assigned to a customer
export const $GET_UNASSIGNED_METERS = gql`
  ${$METER$}
  query unAssignedMeters($property_id: ID!) {
    unAssignedMeters(property_id: $property_id) {
      ...Meter
    }
  }
`;

export const $GET_CUSTOMER = gql`
  ${$METER$}
  ${$METER_PROPERTY_TARIFF$}
  ${$PAYMENT$}
  ${$CUSTOMER$}
  query singleCustomer($customer_id: ID!) {
    singleCustomer: customer(customer_id: $customer_id) {
      ...Customer
      meters {
        ...Meter
        ...MeterPropertyTariff
      }
      payments {
        ...Payment
      }
    }
  }
`;

export const $GET_METER = gql`
  ${$METER$}
  ${$METER_PROPERTY_TARIFF$}
  ${$CUSTOMER$}
  query singleMeter($property_id: ID!, $meter_code: ID!) {
    singleMeter: meter(property_id: $property_id, meter_code: $meter_code) {
      ...Meter
      ...MeterPropertyTariff
      customer {
        ...Customer
      }
    }
  }
`;

export const $GET_METER_BY_SN = gql`
  ${$METER$}
  ${$METER_PROPERTY_TARIFF$}
  ${$CUSTOMER$}
  query meterBySn($serial_number: String!) {
    meterBySn(serial_number: $serial_number) {
      ...Meter
      ...MeterPropertyTariff
      customer {
        ...Customer
      }
    }
  }
`;

export const $GET_CUSTOMERS = gql`
  ${$CUSTOMER$}
  ${$PAYMENT$}
  ${$PAGINATION$}
  query customers($property_id: ID!, $page: Int, $per_page: Int) {
    customers(property_id: $property_id, page: $page, per_page: $per_page) {
      pagination {
        ...Pagination
      }
      data {
        ...Customer
        last_payment {
          ...Payment
        }
      }
    }
  }
`;

// Get properties for the user making this request, also returns the role of the
export const $GET_PROPERTY = gql`
  ${$PROPERTY$}
  query singleProperty($property_id: ID!) {
    singleProperty: property(property_id: $property_id) {
      ...Property
    }
  }
`;

// Get properties for the user making this request
export const $GET_PROPERTIES = gql`
  ${$PROPERTY$}
  query userProperties($page: Int, $per_page: Int) {
    userProperties(page: $page, per_page: $per_page) {
      ...Property
    }
  }
`;


// Get properties for the user making this request, also returns the role of the
export const $GET_PROPERTIES_WITH_ROLE = gql`
  ${$PROPERTY$}
  ${$PAGINATION$}
  query userPropertiesWithRole($page: Int, $per_page: Int) {
    userPropertiesWithRole(page: $page, per_page: $per_page) {
      pagination {
        ...Pagination
      }
      data {
        ...Property
      }
    }
  }
`;

// Get paginated payments for a property
export const $PROPERTY_PAYMENTS = gql`
  ${$PAYMENT$}
  ${$PAGINATION$}
  query propertyPayments($property_id: ID!, $page: Int, $per_page: Int, $filters: PaymentFilters) {
    propertyPayments(
      property_id: $property_id
      page: $page
      per_page: $per_page
      filters: $filters
    ) {
      pagination {
        ...Pagination
      }
      data {
        ...Payment
      }
    }
  }
`;

// Get paginated payments for a meter
export const $METER_PAYMENTS = gql`
  ${$PAYMENT$}
  ${$PAGINATION$}
  query meterPayments(
    $property_id: ID!
    $sn: String!
    $page: Int
    $per_page: Int
    $filters: PaymentFilters
  ) {
    meterPayments(
      property_id: $property_id
      sn: $sn
      page: $page
      per_page: $per_page
      filters: $filters
    ) {
      pagination {
        ...Pagination
      }
      data {
        ...Payment
      }
    }
  }
`;

// Get paginated payments for a customer
export const $CUSTOMER_PAYMENTS = gql`
  ${$PAYMENT$}
  ${$PAGINATION$}
  query customerPayments($property_id: ID!, $customer_id: ID!, $page: Int, $per_page: Int) {
    customerPayments(
      property_id: $property_id
      customer_id: $customer_id
      page: $page
      per_page: $per_page
    ) {
      pagination {
        ...Pagination
      }
      data {
        ...Payment
      }
    }
  }
`;

export const $PAYMENT_TRAIL = gql`
  query paymentTrail($property_id: ID!, $provider_txn_ref: String!) {
    paymentTrail(property_id: $property_id, provider_txn_ref: $provider_txn_ref) {
      payment_trail_id
      txn_ref
      action_slug
      action_result
      action_result_reason
      action_handler
      meta_data
      updated_at
      created_at
    }
  }
`;

// Get all payments within a given time range for a Property
// # from: The start boundary of the range, in 'YYYY-MM-DD
// # hh:mm:ss'. For example: (2020-11-14 11:09:42)
// # to: The end boundary of the range, in 'YYYY-MM-DD hh:mm:ss'.
// # For example: (2020-12-14 11:09:42)
export const $PROPERTY_RANGE_PAYMENTS = gql`
  ${$S3FILE$}
  query paymentRangeData($property_id: ID!, $from: String!, $to: String) {
    paymentRangeData(property_id: $property_id, from: $from, to: $to) {
      ...S3File
    }
  }
`;

export const $PROPERTY_ROLES = gql`
  query propertyRoles {
    propertyRoles {
      role_id
      slug
      title
      created_at
    }
  }
`;

export const $GET_PROPERTY_USERS = gql`
  ${$PROPERTY_USER$}
  query propertyUsers($property_id: ID!) {
    propertyUsers: property(property_id: $property_id) {
      users {
        ...PropertyUser
      }
    }
  }
`;

export const $WALLET = gql`
  ${$WALLET$}
  query propertyWallets($property_id: ID!) {
    propertyWallets: wallets(property_id: $property_id) {
      ...Wallet
    }
  }
`;

export const $ROLE_PERMISSIONS = gql`
  ${$PERMISSIONS$}
  query rolePermissions($role_id: ID!) {
    rolePermissions(role_id: $role_id) {
      ...Permission
    }
  }
`;

export const $PROPERTY_ROLE_PERMISSIONS = gql`
  ${$PROPERTY_PERMISSIONS$}
  query propertyRolePermissions($role_id: ID!) {
    propertyRolePermissions(role_id: $role_id) {
      ...PropertyPermission
    }
  }
`;

export const $GET_PAYMENT = gql`
  ${$PAYMENT$}
  query singlePayment($property_id: ID!, $payment_id: ID!) {
    singlePayment: payment(property_id: $property_id, payment_id: $payment_id) {
      ...Payment
    }
  }
`;

export const $GET_METER_DEBT_LOGS = gql`
  ${$DEBT_LOG$}
  ${$PAGINATION$}
  query meterDebtLogs(
    $property_id: ID!
    $meter_code: ID!
    $page: Int
    $per_page: Int
    $filters: DebtLogFilters
  ) {
    meterDebtLogs(
      property_id: $property_id
      meter_code: $meter_code
      page: $page
      per_page: $per_page
      filters: $filters
    ) {
      pagination {
        ...Pagination
      }
      data {
        ...DebtLog
      }
    }
  }
`;

export const $GET_METER_SCHEDULED_DEBTS = gql`
  ${$SCHEDULED_DEBT$}
  ${$PAGINATION$}
  query meterScheduledDebts(
    $property_id: ID!
    $meter_code: ID!
    $page: Int
    $per_page: Int
    $filters: ScheduledDebtFilters
  ) {
    meterScheduledDebts(
      property_id: $property_id
      meter_code: $meter_code
      page: $page
      per_page: $per_page
      filters: $filters
    ) {
      pagination {
        ...Pagination
      }
      data {
        ...ScheduledDebt
      }
    }
  }
`;

export const $INTERMEDIATE_PAYMENTS = gql`
  ${$INTERMEDIATE_PAYMENT$}
  ${$PAGINATION$}
  query intermediatePayments(
    $property_id: ID!
    $page: Int
    $per_page: Int
    $filters: IntermediatePaymentFilters
  ) {
    intermediatePayments(
      property_id: $property_id
      page: $page
      per_page: $per_page
      filters: $filters
    ) {
      data {
        ...IntermediatePayment
      }
      pagination {
        ...Pagination
      }
    }
  }
`;

export const $SINGLE_PROPERTY_TARIFF = gql`
  ${$PROPERTY_TARIFF$}
  query singlePropertyTariff($property_id: ID!, $tariff_id: ID!) {
    propertyTariff(property_id: $property_id, tariff_id: $tariff_id) {
      ...PropertyTariff
    }
  }
`;

export const $PROPERTY_TARIFFS = gql`
  ${$PROPERTY_TARIFF$}
  query propertyTariffs($property_id: ID!) {
    propertyTariffs(property_id: $property_id) {
      ...PropertyTariff
    }
  }
`;

export const $PROPERTY_AUDIT_TRAIL = gql`
  ${$AUDIT_TRAIL$}
  ${$PAGINATION$}
  query propertyAuditTrail(
    $user_id: ID
    $property_id: ID!
    $resource: String
    $page: Int
    $per_page: Int
  ) {
    propertyAuditTrail(
      user_id: $user_id
      property_id: $property_id
      resource: $resource
      page: $page
      per_page: $per_page
    ) {
      data {
        ...AuditTrail
      }
      pagination {
        ...Pagination
      }
    }
  }
`;

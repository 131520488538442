import { ROUTE } from '@/utilities/constants';
import { type NavItemProps } from 'react-minimal-side-navigation';

export const withActivePropertyId = (
  items: NavItemProps[],
  { activePropertyId, isSettingsAllowed }: { activePropertyId: string; isSettingsAllowed: boolean }
) => {
  if (!activePropertyId) return [];

  const filteredMenuItems = isSettingsAllowed
    ? items
    : items.filter(({ itemId }) => ROUTE.SETTINGS.path !== itemId);

  const menuItems = filteredMenuItems.map((item) => {
    const menuItem = { ...item, itemId: `/${activePropertyId}/${item.itemId}` };
    if (menuItem.subNav) {
      const subNav = withActivePropertyId(menuItem.subNav, {
        activePropertyId,
        isSettingsAllowed,
      }) as NavItemProps[];
      return { ...menuItem, subNav };
    }
    return menuItem;
  });

  return menuItems;
};

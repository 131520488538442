import { useAppDispatch, useAppSelector } from '.';
import { appSettingsActions } from '../slices/appSettings';

export const useAppSettings = () => {
  const settings = useAppSelector((state) => state.AppSettings);
  const dispatch = useAppDispatch();

  const toggleSideMenuExpanded = () => dispatch(appSettingsActions.toggleSideMenuExpanded());

  return {
    ...settings,
    toggleSideMenuExpanded,
  };
};

import styled from 'styled-components';
import Image from '../Image';

export const AvatarImage = styled(Image)`
  img {
    cursor: pointer;
    width: 34px;
    height: 34px;
  }
`;

import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import * as icons from '../assets/icons';

const StyledIcon = styled.div.attrs((props) => ({ ...props }))`
  margin: ${(props: any) => props.margin};
  padding: ${(props: any) => props.padding};
  width: ${({ width }: any) => (width ? `${width}px` : '')};
  height: ${({ height }: any) => (height ? `${height}px` : '')};

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    path {
      fill: ${(props) => props.color} !important;
    }
  }

  .logo svg {
    background: pink !important;
  }

  .rotate {
    animation-name: ckw;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
  }
  @keyframes ckw {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Icon = React.forwardRef<HTMLDivElement | null, any>(
  (
    {
      name = '',
      margin = [],
      padding = [],
      color = '',
      size = 'sm',
      title = '',
      width,
      height,
      onClick,
      rotate = false,
      disabled,
      ...rest
    }: any,
    ref
  ) => (
    <StyledIcon
      margin={margin.length > 0 && `${margin.join('px ')}px;`}
      padding={padding.length > 0 && `${padding.join('px ')}px;`}
      color={color}
      size={size}
      width={width}
      height={height}
      {...rest}
    >
      <div
        ref={ref}
        role="button"
        title={title}
        onClick={(e) => !disabled && onClick && onClick(e)}
        className={clsx(
          !disabled && (onClick ? 'cursor-pointer' : 'cursor-inherit'),
          disabled && 'cursor-not-allowed'
        )}
      >
        {(icons as any)[name.replace(/-/g, '')] && (
          <img
            alt={name}
            src={(icons as any)[name.replace(/-/g, '')]}
            className={`logo ${rotate ? 'rotate' : ''}`.trim()}
            width={width || 'auto'}
            height={height || 'auto'}
          />
        )}
      </div>
    </StyledIcon>
  )
);

export default Icon;

import styled from 'styled-components';

type ShimmerProps = {
  width?: string;
  height?: string;
  rounded?: boolean;
  transparent?: boolean;
};

export const Shimmer = styled.div.attrs(() => ({
  role: 'presentation',
  className: 'loading-skeleton',
}))<ShimmerProps>`
  ${({ width = '', height = '' }) => `
    width: ${width || '100%'};
    min-width: ${width};
    height: ${height || '20px'};
    min-height: ${height};
  `}

  ${({ rounded }) => rounded && 'border-radius: 50%;'}
  ${({ transparent }) => transparent && `background: transparent;`}
`;

export const ShimmerGroup = styled.div.attrs(() => ({ role: 'presentation' }))`
  display: flex;
  gap: 8px;

  ${Shimmer} {
    flex: 0 1 auto;
  }
`;

import CreateProperty from '../property/CreateProperty';
import { useMemo, useState } from 'react';
import { Container } from '@/components/Container';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { userPropertiesWithRole } from '@/hooks/userPropertiesWithRole';
import { Table } from '@/components/Table';
import { createPropertiesColumns } from '@/configs/columns/createPropertiesColumns';
import { Grid } from '@/components/Grid';
import { Pagination } from '@/components/Table/Pagination';

const Dashboard = () => {
  const [showCreateProperty, setShowCreateProperty] = useState(false);
  const {
    page,
    properties = [],
    isLoading,
    fetchProperties,
  } = userPropertiesWithRole({ per_page: 50 });

  const columns = useMemo(() => createPropertiesColumns(), []);

  const handlePageChange = (page: { page: number; size: number }) => {
    fetchProperties({ page: page.page, per_page: page.size });
  };

  return (
    <>
      <Container
        header="Dashboard"
        action={
          <Button onClick={() => setShowCreateProperty(true)}>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faBuilding} /> Add Property
            </div>
          </Button>
        }
      >
        <Table
          data={properties}
          columns={columns}
          page={page}
          isLoading={isLoading}
          noOfLoadingRows={10}
          noData={
            <Grid alignX="center" spacing="lg">
              <span>You do not have properties to view.</span>
              <Button onClick={() => setShowCreateProperty(true)}>
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faBuilding} /> Create one
                </div>
              </Button>
            </Grid>
          }
        />
        {page && <Pagination page={page} handlePageChange={handlePageChange} />}
      </Container>
      {showCreateProperty && <CreateProperty onClose={() => setShowCreateProperty(false)} />}
    </>
  );
};

export default Dashboard;

import { Shimmer } from '@/components/Shimmer/Shimmer';
import { Column, Table, flexRender } from '@tanstack/react-table';

export const renderLoadingRow = <T,>(visible: Column<T>[], key: string | number) => (
  <tr key={key}>
    {visible.map((cell) => (
      <td key={cell.id}>
        <Shimmer width={String(Math.min(cell.getSize(), 50) + '%')} />
      </td>
    ))}
  </tr>
);

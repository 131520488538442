import type { HTMLFormEventHandler, InputChangeEventHandler } from '@/types/form';
import { useState } from 'react';
import Toast from 'react-hot-toast';
import { Modal } from '@/components/Modal';
import Button from '@/components/Button';
import { PhoneInput, Input, PasswordInput } from '@/components/inputs';
import { useAppDispatch } from '@/store/hooks';
import { userActions } from '@/store/slices';
import { useCurrentUser } from '@/hooks/useCurrentUser';
import { Grid } from '@/components/Grid';
import { useUpdateUser } from '@/hooks/useUpdateUser';
import { useUpdatePassword } from '@/hooks/useUpdatePassword';
import { userAdapter } from '@/adapters';

type UserProfileProps = {
  show: boolean;
  onClose: () => void;
};

const UserProfile = ({ show, onClose }: UserProfileProps) => {
  const { user } = useCurrentUser();
  const dispatch = useAppDispatch();
  const { updateUser, isMutating: isMutatingUser } = useUpdateUser();
  const { updatePassword, isMutating: isMutatingPassword } = useUpdatePassword();

  const [formData, setFormData] = useState({
    firstname: user?.firstName ?? '',
    lastname: user?.lastName ?? '',
    location: user?.location ?? '',
    phone_number: user?.phoneNumber ?? '',
    old_password: '',
    new_password: '',
    confirm_password: '',
  });

  const handleSubmit: HTMLFormEventHandler = (e) => {
    e.preventDefault();
    const { firstname, lastname, location, phone_number } = formData;

    updateUser({ firstname, lastname, location, phone_number }).then((response) => {
      if (user && response) {
        const { firstName, lastName, location, phoneNumber } = userAdapter(response);
        dispatch(userActions.updateUser({ ...user, firstName, lastName, location, phoneNumber }));
        Toast.success('Profile updated successfully!');
        onClose?.();
      }
    });
  };

  const handlePasswordChange: HTMLFormEventHandler = (e) => {
    e.preventDefault();
    const { old_password, new_password, confirm_password } = formData;

    if (new_password && new_password === confirm_password) {
      updatePassword({ old_password, password: new_password }).then((message) => {
        if (message) {
          Toast.success('Password successfully changed!');
          onClose?.();
        }
      });
    } else {
      Toast.error('Passwords do not match');
    }
  };

  const handleInputChange: InputChangeEventHandler = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <Modal show={show} onClose={onClose} title="Update Profile">
      <Grid spacing="lg">
        <Grid as="form" spacing="lg" onSubmit={handleSubmit}>
          <Grid grid columns={2} spacing="lg">
            <Input
              label="First Name"
              placeholder="Enter First Name"
              name="firstname"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={formData.firstname}
            />
            <Input
              label="Last Name"
              placeholder="Enter Last Name"
              name="lastname"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={formData.lastname}
            />
            <Input
              disabled
              label="Email"
              placeholder="Contact Email"
              name="email"
              type="email"
              defaultValue={user?.email}
            />

            <PhoneInput
              label="Phone Number"
              placeholder="Contact Phone Number e.g +234 90xxxxxxxx"
              name="phone_number"
              validate="required|phone_number"
              onChange={handleInputChange}
              defaultValue={formData.phone_number}
            />
          </Grid>

          <Grid>
            <Input
              label="Location"
              placeholder="Location"
              name="location"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={formData.location}
            />
          </Grid>

          <Grid alignX="center">
            <Button type="submit" value="Update" size="md" width={100} loading={isMutatingUser} />
          </Grid>
        </Grid>

        <Grid as="form" spacing="lg" onSubmit={handlePasswordChange}>
          <h6 className="pt-3 my-0 pb-1 text-xl" style={{ borderTop: '1px solid #dee2e6' }}>
            Password
          </h6>

          <Grid grid columns={2} spacing="lg">
            <PasswordInput
              label="Current Password"
              placeholder="Current Password"
              name="old_password"
              validate="required"
              onChange={handleInputChange}
              defaultValue={formData.old_password}
            />

            <PasswordInput
              label="New Password"
              placeholder="New Password"
              name="new_password"
              validate="required|password"
              onChange={handleInputChange}
              defaultValue={formData.new_password}
            />

            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm Password"
              name="confirm_password"
              validate="required|password"
              onChange={handleInputChange}
              defaultValue={formData.confirm_password}
            />
          </Grid>

          <Grid alignX="center">
            <Button
              type="submit"
              value="Change Password"
              size="md"
              width={160}
              loading={isMutatingPassword}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UserProfile;

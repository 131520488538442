import { Avatar } from '@/components/Avatar';
import { Shimmer } from '@/components/Shimmer/Shimmer';
import TruncatedText from '@/components/Truncated';
import { useCurrentUser } from '@/hooks/useCurrentUser';

type CurrentUserProps = {
  onClick: () => void;
  showNameOnly?: boolean;
};

export const CurrentUser = ({ onClick, showNameOnly }: CurrentUserProps) => {
  const { user, isLoading: isUserLoading, hasError } = useCurrentUser();

  if (isUserLoading && showNameOnly) {
    return <Shimmer transparent height="16px" />;
  }

  if (isUserLoading) {
    return <Shimmer transparent rounded width="34px" height="32px" />;
  }

  if (hasError || !user) {
    return <small className="block text-xs text-center text-red-500">An error occurred</small>;
  }

  if (showNameOnly) {
    return <small title={user?.fullName}>👋 {user?.fullName}!</small>;
  }

  return <Avatar user={user} onClick={onClick} />;
};

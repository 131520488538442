import { HeaderGroup, flexRender } from '@tanstack/react-table';

export const renderFooter = <T,>(footerGroup: HeaderGroup<T>) => (
  <tr key={footerGroup.id}>
    {footerGroup.headers.map((header) => (
      <th key={header.id} colSpan={header.colSpan}>
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.footer, header.getContext())}
      </th>
    ))}
  </tr>
);

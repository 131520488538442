import { useCurrentUser } from '@/hooks/useCurrentUser';
import { AvatarProps } from './types';
import { AvatarImage } from './styled';

export const Avatar = ({ user, onClick }: AvatarProps) => {
  const avatarUrl =
    `https://ui-avatars.com/api/?background=ffffff&color=random&bold=true&rounded=true&name=${user?.fullName}` ||
    user?.picture;

  return (
    <AvatarImage
      src={avatarUrl}
      title="Click to edit profile"
      name={user?.fullName}
      onClick={onClick}
    />
  );
};

import { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledImage = styled.div.attrs((props: any) => ({ ...props }))`
  margin: ${({ margin }: any) => margin};
  padding: ${({ padding }: any) => padding};
  border-radius: ${({ round }: any) => (round ? '50%' : 'none')};
  overflow: hidden;

  img {
    margin: auto;
    width: ${({ width }: any) => (width ? `${width}px` : '')};
    height: ${({ height }: any) => (height ? `${height}px` : '')};
  }
`;

const Image = ({
  alt,
  src = '',
  name = '',
  margin = [],
  padding = [],
  color = '',
  size = 'sm',
  width,
  height,
  round,
  title,
  onClick,
  ...rest
}: any) => {
  const fallback = `https://ui-avatars.com/api/?background=dde4ee&color=2759a2&rounded=true&name=${
    alt || 'Image'
  }`;
  const [source, setSource] = useState(src || fallback);
  const handelOnError = () => {
    if (source !== fallback) {
      setSource(fallback);
    }
  };

  useEffect(() => {
    if (src) setSource(src);
  }, [src]);

  return (
    <StyledImage
      margin={
        margin.length > 0 &&
        `${margin.map((m: string | number) => (Number(m) ? `${m}px` : m)).join(' ')};`
      }
      padding={
        padding.length > 0 &&
        `${padding.map((p: string | number) => (Number(p) ? `${p}px` : p)).join(' ')};`
      }
      color={color}
      size={size}
      width={width}
      height={height}
      round={round}
      {...rest}
    >
      <img src={source} title={title} alt={name} onClick={onClick} onError={handelOnError} />
    </StyledImage>
  );
};

export default Image;

import clsx from 'clsx';

type BadgeProps = { label: string; color?: string; size?: 'md' };
export const Badge = ({ label, color, size }: BadgeProps) => {
  return (
    <>
      <span
        className={clsx(
          'rounded-full px-2 py-1 font-normal leading-none text-white text-center whitespace-nowrap inline-block align-baseline bg-slate-500',
          size === 'md' ? 'text-[11px]' : 'text-[9px]'
        )}
        style={{ background: color }}
      >
        {label}
      </span>
    </>
  );
};

import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap';
import type { TooltipProps } from './types';

export const Tooltip = ({
  action = ['hover', 'focus'],
  trigger,
  content,
  placement,
}: TooltipProps) => (
  <OverlayTrigger trigger={action} overlay={<BTooltip>{content}</BTooltip>} placement={placement}>
    {typeof trigger === 'string' ? <span>{trigger}</span> : trigger}
  </OverlayTrigger>
);

import { userProperties } from '@/hooks/userProperties';
import { useAppDispatch } from '@/store/hooks';
import { useLoggedIn } from '@/store/hooks/useLoggedIn';
import { propertyActions } from '@/store/slices';
import { useEffect } from 'react';
import { Navigate, useLocation, Outlet, useParams } from 'react-router-dom';

const PrivateRoute = () => {
  const location = useLocation();
  const { isLoggedIn } = useLoggedIn();

  if (isLoggedIn) {
    return <Outlet />;
  }
  return <Navigate to="/auth/signin" state={{ from: location }} replace />;
};

const PropertyRoute = () => {
  const location = useLocation();
  const { propertyId } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading, properties } = userProperties();

  const hasProperty = properties?.find((property) => property.propertyId === propertyId);
  const isOutletAllowed = Boolean(isLoading || hasProperty);

  useEffect(() => {
    if (!isOutletAllowed) {
      dispatch(propertyActions.clearActivePropertyId());
    }
  }, [isOutletAllowed]);

  if (isOutletAllowed) {
    return <Outlet />;
  }

  return <Navigate to="/" state={{ from: location }} replace />;
};

const AuthRoute = () => {
  const { isLoggedIn } = useLoggedIn();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export { AuthRoute, PrivateRoute, PropertyRoute };

import gql from 'graphql-tag';
import {
  $CUSTOMER$,
  $METER$,
  $PAYMENT$,
  $PROPERTY$,
  $PROPERTY_USER$,
  $SCHEDULED_DEBT$,
  $USER$,
  $WALLET$,
  $SUCCESS_RESULT$,
  $PROPERTY_TARIFF$,
  $METER_PROPERTY_TARIFF$,
} from './fragments';

export const $SIGN_IN = gql`
  ${$USER$}
  mutation signin($email: EmailAddress!, $password: String!) {
    signin(email: $email, password: $password) {
      ...User
    }
  }
`;

export const $CREATE_PROPERTY = gql`
  ${$PROPERTY$}
  mutation createProperty(
    $name: String!
    $email: EmailAddress!
    $phone_number: PhoneNumber
    $url: URL
    $address: String
    $about: String
    $description: String
    $subaccount_id: String
    $remmital_percentage: String
    $enforce_krn_update: Boolean
  ) {
    createProperty(
      name: $name
      email: $email
      phone_number: $phone_number
      url: $url
      address: $address
      about: $about
      description: $description
      subaccount_id: $subaccount_id
      remmital_percentage: $remmital_percentage
      enforce_krn_update: $enforce_krn_update
    ) {
      ...Property
    }
  }
`;

export const $UPDATE_PROPERTY = gql`
  ${$PROPERTY$}
  mutation updateProperty(
    $property_id: ID!
    $name: String
    $address: String
    $about: String
    $description: String
    $email: EmailAddress
    $logo: Upload
    $phone_number: PhoneNumber
    $url: URL
    $subaccount_id: String
    $remmital_percentage: String
    $vat: String
    $enforce_krn_update: Boolean
    $estate_max_flat_charge: Float
    $system_flat_charge: Float
    $default_tariff_id: ID
  ) {
    updateProperty(
      property_id: $property_id
      name: $name
      address: $address
      about: $about
      description: $description
      email: $email
      logo: $logo
      phone_number: $phone_number
      url: $url
      subaccount_id: $subaccount_id
      remmital_percentage: $remmital_percentage
      vat: $vat
      enforce_krn_update: $enforce_krn_update
      estate_max_flat_charge: $estate_max_flat_charge
      system_flat_charge: $system_flat_charge
      default_tariff_id: $default_tariff_id
    ) {
      ...Property
    }
  }
`;

export const $DELETE_PROPERTY = gql`
  ${$PROPERTY$}
  mutation deleteProperty($property_id: ID!, $property_code: String!) {
    deleteProperty(property_id: $property_id, property_code: $property_code)
  }
`;

export const $UPDATE_USER_ROLE = gql`
  ${$PROPERTY_USER$}
  mutation updatePropertyUserRole($property_id: ID!, $user_id: ID!, $role_id: ID!) {
    updatePropertyUserRole(property_id: $property_id, user_id: $user_id, role_id: $role_id) {
      ...PropertyUser
    }
  }
`;

// Update user account
export const $UPDATE_USER = gql`
  ${$USER$}
  mutation updateUser(
    $firstname: String
    $lastname: String
    $phone_number: PhoneNumber
    $company: String
    $picture: Upload
    $location: String
    $job_title: String
    $department: String
  ) {
    updateUser: user(
      firstname: $firstname
      lastname: $lastname
      phone_number: $phone_number
      company: $company
      picture: $picture
      location: $location
      job_title: $job_title
      department: $department
    ) {
      ...User
    }
  }
`;

// Delete a user account
export const $DELETE_USER = gql`
  mutation deleteAccount($email: EmailAddress!) {
    deleteAccount(email: $email)
  }
`;

// Update the password for a user
export const $UPDATE_PASSWORD = gql`
  mutation updatePassword($old_password: String!, $password: String!) {
    updatePassword(old_password: $old_password, password: $password)
  }
`;

// Register a new user
export const $SIGN_UP = gql`
  ${$USER$}
  mutation signup(
    $email: EmailAddress!
    $password: String!
    $firstname: String!
    $lastname: String!
  ) {
    signup(email: $email, password: $password, firstname: $firstname, lastname: $lastname) {
      ...User
    }
  }
`;

// Update the password for a user
export const $RESET_PASSWORD = gql`
  mutation resetPassword($reset_token: String!, $password: String!) {
    resetPassword(reset_token: $reset_token, password: $password)
  }
`;

// Allows an Admin to create a single customer
export const $CREATE_CUSTOMER = gql`
  ${$CUSTOMER$}
  mutation createCustomer(
    $property_id: ID!
    $meter_code: ID
    $firstname: String
    $lastname: String
    $email: EmailAddress!
    $location: String
    $phone_number: PhoneNumber
  ) {
    createCustomer(
      property_id: $property_id
      meter_code: $meter_code
      firstname: $firstname
      lastname: $lastname
      location: $location
      email: $email
      phone_number: $phone_number
    ) {
      ...Customer
    }
  }
`;

// Allows an Admin to create a multiple customer
// # CSV Example:
// # email,firstname,lastname,phone_number,meter_number
// # albertilesanmi@gmail.com,Albert,Ilesanmi,+234 9053006654,12345678977
// # tobiilesanmi@gmail.com,AlbertTobi,Ilesanmi,+234 9054006654,12345678978
export const $CREATE_CUSTOMERS_CSV = gql`
  ${$CUSTOMER$}
  mutation addMultipleCustomersByCSV($property_id: ID!, $file: Upload!) {
    addMultipleCustomersByCSV(property_id: $property_id, file: $file) {
      ...Customer
    }
  }
`;

// Allows an Admin to update the details of a customer
export const $UPDATE_CUSTOMER = gql`
  ${$CUSTOMER$}
  ${$PAYMENT$}
  mutation updateCustomer(
    $property_id: ID!
    $customer_id: ID!
    $firstname: String
    $lastname: String
    $phone_number: PhoneNumber
    $email: EmailAddress
    $location: String
  ) {
    updateCustomer(
      property_id: $property_id
      customer_id: $customer_id
      firstname: $firstname
      lastname: $lastname
      phone_number: $phone_number
      email: $email
      location: $location
    ) {
      ...Customer
      last_payment {
        ...Payment
      }
    }
  }
`;

export const $DELETE_CUSTOMER = gql`
  mutation deleteCustomer($property_id: ID!, $customer_id: ID!) {
    deleteCustomer(property_id: $property_id, customer_id: $customer_id) {
      success
    }
  }
`;

// Allows an Admin to link a single customer and to a Meter
export const $LINK_CUSTOMER_TO_METER = gql`
  mutation linkCustomerToMeter($property_id: ID!, $meter_code: ID!, $customer_id: ID!) {
    linkCustomerToMeter(
      property_id: $property_id
      meter_code: $meter_code
      customer_id: $customer_id
    )
  }
`;

// Allows an Admin to unlink a single user from a Meter
export const $UNLINK_CUSTOMER_TO_METER = gql`
  mutation unlinkCustomerFromMeter($property_id: ID!, $meter_code: ID!, $customer_id: ID!) {
    unlinkCustomerFromMeter(
      property_id: $property_id
      meter_code: $meter_code
      customer_id: $customer_id
    )
  }
`;

// Allows an Admin to create multiple users and auto-assign them to a Property
export const $CREATE_USERS_CSV = gql`
  ${$USER$}
  mutation addMultipleUsersByCSV($property_id: ID!, $role_id: ID!, $file: Upload!) {
    addMultipleUsersByCSV(property_id: $property_id, role_id: $role_id, file: $file) {
      ...User
    }
  }
`;

// Allows an Admin to create multiple users and auto-assign them to a Property
export const $CREATE_USER = gql`
  ${$USER$}
  mutation addSingleUser(
    $property_id: ID!
    $role_id: ID!
    $email: String!
    $firstname: String!
    $lastname: String!
    $phone_number: String!
  ) {
    addSingleUser(
      property_id: $property_id
      role_id: $role_id
      email: $email
      firstname: $firstname
      lastname: $lastname
      phone_number: $phone_number
    ) {
      ...User
    }
  }
`;

// Allows an Admin to unlink a single user from a Property
export const $REMOVE_USER = gql`
  mutation removeUser($property_id: ID!, $user_id: ID!) {
    removeUser(property_id: $property_id, user_id: $user_id)
  }
`;

// Allows an Admin to unlink a single user from a Property
export const $CREATE_METER = gql`
  ${$METER$}
  ${$METER_PROPERTY_TARIFF$}
  mutation createMeter(
    $property_id: ID!
    $name: String!
    $sn: String!
    $address: String
    $meter_type: String!
    $meter_billing_type: String!
    $comms: String
    $debt: String
    $type: String!
    $is_enabled: Boolean
    $has_camera: Boolean
    $ea: Float
    $tct: Float
    $sgc: Float!
    $ti: Float!
    $krn: Float
    $ken: Float
    $property_tariff_id: ID
  ) {
    createMeter(
      property_id: $property_id
      name: $name
      sn: $sn
      address: $address
      meter_type: $meter_type
      meter_billing_type: $meter_billing_type
      comms: $comms
      debt: $debt
      type: $type
      is_enabled: $is_enabled
      has_camera: $has_camera
      ea: $ea
      tct: $tct
      sgc: $sgc
      ti: $ti
      krn: $krn
      ken: $ken
      property_tariff_id: $property_tariff_id
    ) {
      ...Meter
      ...MeterPropertyTariff
    }
  }
`;

// Allows an Admin to create a multiple meters
// # CSV Example:
// # name,sn,meter_tariff,meter_type,meter_billing_type,debt,units,type
// # Mojec 1,12345678976,53.45,THREE_PHASE,PREPAID,1000,200,wuxi_taojin_3ph
export const $CREATE_METERS_CSV = gql`
  ${$METER$}
  mutation addMultipleMetersByCSV($property_id: ID!, $file: Upload!) {
    addMultipleMetersByCSV(property_id: $property_id, file: $file) {
      ...Meter
    }
  }
`;

// Update the details of a Meter
export const $UPDATE_METER = gql`
  ${$METER$}
  ${$METER_PROPERTY_TARIFF$}
  mutation updateMeter(
    $property_id: ID!
    $meter_code: ID!
    $name: String!
    $address: String
    $type: String!
    $meter_type: String
    $comms: String
    $is_enabled: Boolean
    $has_camera: Boolean
    $ea: Float
    $tct: Float
    $sgc: Float
    $ti: Float
    $krn: Float
    $ken: Float
    $property_tariff_id: ID
  ) {
    updateMeter(
      property_id: $property_id
      meter_code: $meter_code
      name: $name
      address: $address
      type: $type
      meter_type: $meter_type
      comms: $comms
      is_enabled: $is_enabled
      has_camera: $has_camera
      ea: $ea
      tct: $tct
      sgc: $sgc
      ti: $ti
      krn: $krn
      ken: $ken
      property_tariff_id: $property_tariff_id
    ) {
      ...Meter
      ...MeterPropertyTariff
    }
  }
`;

export const $DELETE_METER = gql`
  mutation deleteMeter($property_id: String!, $meter_code: String!) {
    deleteMeter(property_id: $property_id, meter_code: $meter_code) {
      success
    }
  }
`;

// Update meter billing details
export const $UPDATE_METER_BILLING = gql`
  ${$METER$}
  ${$METER_PROPERTY_TARIFF$}
  mutation updateMeterBilling(
    $property_id: ID!
    $meter_code: ID!
    $tariff: Float
    $debt: Float
    $billing_type: String
  ) {
    updateMeterBilling(
      property_id: $property_id
      meter_code: $meter_code
      tariff: $tariff
      billing_type: $billing_type
      debt: $debt
    ) {
      ...Meter
      ...MeterPropertyTariff
    }
  }
`;

// Create a new Payment
export const $CREATE_PAYMENT = gql`
  ${$PAYMENT$}
  mutation makePayment(
    $meter_code: ID!
    $property_id: ID!
    $amount_paid: Float!
    $payment_status: String!
    $raw_response: JSONObject!
  ) {
    payment(
      meter_code: $meter_code
      property_id: $property_id
      amount_paid: $amount_paid
      payment_status: $payment_status
      raw_response: $raw_response
    ) {
      ...Payment
    }
  }
`;

// Retry a failed token generation for an existing successful payment
export const $PAYMENT_TOKEN_RETRY = gql`
  ${$PAYMENT$}
  mutation paymentTokenRetry($meter_sn: String!, $property_id: ID!, $transaction_ref: String!) {
    paymentTokenRetry(
      meter_sn: $meter_sn
      property_id: $property_id
      transaction_ref: $transaction_ref
    ) {
      ...Payment
    }
  }
`;

// Retry a failed payment for an existing Payment
export const $PAYMENT_REQUERY = gql`
  ${$PAYMENT$}
  mutation paymentRequery($meter_sn: String!, $property_id: ID!, $transaction_ref: String!) {
    paymentRequery(
      meter_sn: $meter_sn
      property_id: $property_id
      transaction_ref: $transaction_ref
    ) {
      ...Payment
    }
  }
`;

export const $UPDATE_WALLET_BALANCE = gql`
  ${$WALLET$}
  mutation updateWalletBalance($property_id: ID!, $wallet_id: ID!, $balance_delta: Float!) {
    updateWalletBalance(
      property_id: $property_id
      wallet_id: $wallet_id
      balance_delta: $balance_delta
    ) {
      ...Wallet
    }
  }
`;

export const $UPDATE_METER_DEBT = gql`
  ${$METER$}
  mutation updateMeterDebt(
    $property_id: ID!
    $sn: String!
    $debt_delta: Float!
    $debt_type: String
  ) {
    updateMeterDebt(
      property_id: $property_id
      sn: $sn
      debt_delta: $debt_delta
      debt_type: $debt_type
    ) {
      ...Meter
    }
  }
`;

export const $UPDATE_METER_DEBTS_CSV = gql`
  ${$METER$}
  mutation updateMeterDebtsByCSV($property_id: ID!, $file: Upload!) {
    updateMeterDebtsByCSV(property_id: $property_id, file: $file) {
      ...Meter
    }
  }
`;

export const $ADD_METER_SCHEDULED_DEBT = gql`
  ${$SCHEDULED_DEBT$}
  mutation addMeterScheduledDebt(
    $property_id: ID!
    $sn: String!
    $debt_amount: Float!
    $debt_type: String
    $debt_interval: String
  ) {
    addMeterScheduledDebt(
      property_id: $property_id
      sn: $sn
      debt_amount: $debt_amount
      debt_type: $debt_type
      debt_interval: $debt_interval
    ) {
      ...ScheduledDebt
    }
  }
`;

export const $ADD_METER_SCHEDULED_DEBTS_CSV = gql`
  ${$SCHEDULED_DEBT$}
  mutation addMeterScheduledDebtsByCSV($property_id: ID!, $file: Upload!) {
    addMeterScheduledDebtsByCSV(property_id: $property_id, file: $file) {
      ...ScheduledDebt
    }
  }
`;

export const $DELETE_METER_SCHEDULED_DEBT = gql`
  ${$SUCCESS_RESULT$}
  mutation deleteMeterScheduledDebt($property_id: ID!, $meter_code: ID!, $debt_id: ID!) {
    deleteMeterScheduledDebt(
      property_id: $property_id
      meter_code: $meter_code
      debt_id: $debt_id
    ) {
      ...SuccessResult
    }
  }
`;

export const $UPLOAD_TOKEN = gql`
  mutation uploadToken($sn: String!, $transaction_ref: String!) {
    uploadToken(sn: $sn, transaction_ref: $transaction_ref) {
      meter_number
      meter_name
      uuid
    }
  }
`;

/* Property Tariff Index */
//  create property tariff
export const $ADD_PROPERTY_TARIFF = gql`
  ${$PROPERTY_TARIFF$}
  mutation addPropertyTariff($property_id: ID!, $name: String!, $tariff: Float!) {
    addPropertyTariff(property_id: $property_id, name: $name, tariff: $tariff) {
      ...PropertyTariff
    }
  }
`;

// update property tariff
export const $UPDATE_PROPERTY_TARIFF = gql`
  ${$PROPERTY_TARIFF$}
  mutation updatePropertyTariff(
    $property_id: ID!
    $tariff_id: ID!
    $name: String
    $tariff: Float!
  ) {
    updatePropertyTariff(
      property_id: $property_id
      tariff_id: $tariff_id
      name: $name
      tariff: $tariff
    ) {
      ...PropertyTariff
    }
  }
`;

// remove property tariff
export const $REMOVE_PROPERTY_TARIFF = gql`
  ${$SUCCESS_RESULT$}
  mutation removePropertyTariff($property_id: ID!, $tariff_id: ID!) {
    removePropertyTariff(property_id: $property_id, tariff_id: $tariff_id) {
      ...SuccessResult
    }
  }
`;

// assign property tariff to meter
export const $ASSIGN_PROPERTY_TARIFF_TO_METER = gql`
  ${$SUCCESS_RESULT$}
  mutation assignPropertyTariffToMeter($property_id: ID!, $tariff_id: ID!, $meter_code: ID!) {
    assignPropertyTariffToMeter(
      property_id: $property_id
      tariff_id: $tariff_id
      meter_code: $meter_code
    ) {
      ...SuccessResult
    }
  }
`;

// unassign property tariff to meter
export const $UNASSIGN_PROPERTY_TARIFF_TO_METER = gql`
  ${$SUCCESS_RESULT$}
  mutation unassignPropertyTariffToMeter($property_id: ID!, $tariff_id: ID!, $meter_code: ID!) {
    unassignPropertyTariffToMeter(
      property_id: $property_id
      tariff_id: $tariff_id
      meter_code: $meter_code
    ) {
      ...SuccessResult
    }
  }
`;
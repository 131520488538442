import styled from 'styled-components';

export const StyledLogo = styled.div`
  margin: 0 3px 16px;
  border-bottom: 2px solid #3d70b9;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
`;

export const StyledActionArea = styled.div`
  padding: 8px 0 0;
  height: 190px;

  &[aria-expanded] {
    padding: 0;

    > .profile {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 130px;
    }
  }

  > .profile {
    background: #3d70b9;
    height: auto;
    max-height: 140px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  > div {
    min-height: 50px;
    border-radius: 4px;
    padding: 10px;
    text-align: center;

    .dropdown a {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 12px;
      padding-left: 15px;

      &.dropdown-toggle {
        padding: 0;
        margin-bottom: 10px;
      }
    }

    .dropdown-menu {
      background: #ffffff;

      a {
        color: #333333;
        padding: 10px;

        &:hover {
          color: #2659a2;
        }
      }
    }
  }

  &[aria-expanded='false'] > div {
    padding: 10px 0;
    background: transparent;
  }
`;

export const StyledSideMenu = styled.div`
  background: #2659a2 !important;
  color: #ffffff !important;
  height: 100vh !important;
  overflow: auto;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease;
  overflow-x: hidden;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  padding: 16px;
  position: relative;

  &[aria-expanded='true'] {
    min-width: 252px;
    max-width: 252px;

    .side-navigation-panel-select-option-text {
      margin-left: 16px !important;
    }

    .side-navigation-panel-select-option,
    .side-navigation-panel-select-inner-option {
      padding: 8px !important;
      padding: 8px 14px !important;
      border-left-width: 3px !important;
    }

    .side-navigation-panel-select-option-selected,
    .side-navigation-panel-select-inner-option-selected,
    .side-navigation-panel-select-option:hover,
    .side-navigation-panel-select-inner-option:hover {
      border-left-width: 3px !important;
    }
  }

  li * {
    color: #ffffff !important;
    font-weight: 300 !important;
    font-size: 14px !important;
  }

  .side-navigation-panel {
    flex: 1;
  }

  .side-navigation-panel-select-option {
    display: flex;
    justify-content: flex-start !important;
    margin-top: 30px !important;
    img {
      cursor: pointer;
    }
  }

  .side-navigation-panel-select-option-text {
    margin-left: 40px !important;
  }

  .side-navigation-panel-select-inner-option {
    margin-top: 16px !important;
    padding-left: 60px !important;
    height: 32px !important;
  }

  .side-navigation-panel-select-option,
  .side-navigation-panel-select-inner-option {
    background: #2659a2 !important;
    border-color: #2659a2;
    border-left-width: 2px !important;
    color: #ffffff !important;
    padding: 8px 10px !important;
    height: 24px !important;
    transition: all 250ms ease !important;
  }

  .side-navigation-panel-select-option-selected,
  .side-navigation-panel-select-inner-option-selected,
  .side-navigation-panel-select-option:hover,
  .side-navigation-panel-select-inner-option:hover {
    background: #2659a2 !important;
    border-color: #ffffff !important;
    border-left-width: 2px !important;
  }

  .side-navigation-panel-select-option-selected,
  .side-navigation-panel-select-inner-option-selected {
    span *,
    & {
      font-weight: 500 !important;
    }
  }

  .role-badge {
    margin-bottom: 16px;

    span {
      color: #2659a2;
      font-size: 11px;
      padding: 1px 4px;
      border-radius: 8px;
      background-color: #ffffff;
    }
  }
`;

export const MenuToggleWrapper = styled.div`
  position: absolute;
  left: 4px;
  top: 4px;
  z-index: 1;
  color: white;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const CreatePropertyWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
`;

export const SideMenuBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  margin: 40px 20px 20px;

  img {
    cursor: pointer;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
